import { message } from "antd";
import { getErrorMsg, DEFAUlT_MSG } from "./message";
const TOKEN_EXPIRED_CODE = 1010; // 登录过期
function AxiosCreate(instance) {
  // 添加请求拦截器
  instance.interceptors.request.use(
    function (config) {
      // let token = localStorage.getItem("token");
      // if (token) {
      //   config.headers.token = { token };
      // }
      return config;
    },
    // 对请求错误做些什么
    function (error) {
      return Promise.reject(error);
    }
  );
  // 添加响应拦截器
  instance.interceptors.response.use(function (response) {
    const url = window.location;
    const { err } = response.data;
    if (err) {
      const { auto, msg } = getErrorMsg(err);
      if (auto) {
        /* 不自动弹出的可能是不处理或者需要程序单独处理 */
        message.error(msg);
        if (err === 1010) {
          window.location.href = `${url.origin}/login`;
        } else if (err === 1011) {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          console.log(err, "err");
        }
      }
      // if (!msg) {
      //   console.error(
      //     `${response.config.url} 返回未知错误码[${response.data.err}]`
      //   );
      //   message.error(DEFAUlT_MSG);
      // }

      let targetUrl;
      // 账号登陆超时，请重新登录
      if (err === TOKEN_EXPIRED_CODE) {
        // 清除本地登录信息
        localStorage.removeItem("token");
        localStorage.removeItem("account");
        const targetParams = encodeURIComponent(`${url.pathname}${url.search}`);
        targetUrl = `${url.origin}/login?redirect=${targetParams}`;
      }
    }
    return response;
  });
}
export default AxiosCreate;
