import React, { useEffect, useState } from "react";
import qs from "qs";
import axios from "axios";
import { APP_VERSION_STATUS, ALL_CHANNEL } from "../../../config/constant";
import { Form, Row, Col, message, Button } from "antd";
import { appVersionGet } from "../../../api/api";
import { useLocation } from "react-router-dom";
export default function AppVersionDetail() {
  const location = useLocation();
  const [versionInfo, setVersionInfo] = useState({});
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getVersionInfo = () => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    if (params.versionId) {
      allParams.version = params.versionId;
      allParams.channel = params.channel;
    }
    axios
      .post(appVersionGet, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const { appversion } = res.data.data;
          setVersionInfo(appversion);
        } else if (res.data.err === 1021) {
          message.error("版本号不存在");
        } else {
          message.error("获取版本信息详情失败");
        }
      });
  };
  useEffect(() => {
    getVersionInfo();
  }, []);
  const returnIvfList = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="userInfo">
        <Button
          onClick={returnIvfList}
          className="return"
          type="primary"
          style={{ display: !params.editionId && "none" }}
        >
          返回ivf版本列表
        </Button>
        <div className="topTitle">版本详情</div>
        <Form className="form">
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="version" label="版本号">
                <div className="right">{versionInfo.version}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="status" label="状态">
                <div className="right">
                  {APP_VERSION_STATUS[versionInfo.status]}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="channel" label="用户渠道">
                <div className="right">
                  {ALL_CHANNEL[versionInfo.channel]
                    ? ALL_CHANNEL[versionInfo.channel]
                    : versionInfo.channel}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="url" label="商店链接">
                <div className="right">
                  {versionInfo.url ? versionInfo.url : "暂无"}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={22}>
              <Form.Item name="description" label="版本描述">
                <div
                  className="right msgInfo"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {versionInfo.description ? versionInfo.description : "-"}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
