import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  Tag,
  Pagination,
  Row,
  message,
  Col,
  Spin,
} from "antd";
import "moment/locale/zh-cn";
import { Link } from "react-router-dom";

import {
  INVENTORY_INFO,
  ALL_CHANNEL,
  PAY_STATUS,
  distint,
  DEVICE_ARR,
} from "../../../config/constant";
import locale from "antd/es/date-picker/locale/zh_CN";
import {
  GOODS_TYPE,
  filterParams,
  formatTime,
  ORDER_STATUS,
} from "../../../config/constant";
import "./List.scss";
import { noBillSupplementListApi, channelApi } from "../../../api/api";
import moment from "moment";
import axios from "axios";
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function Orders() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [condition, setCondition] = useState();
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  const [channelList, setChannelList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channelObj, setChannelObj] = useState({});

  const handleSubmit = (values) => {
    let params = {};
    // 点击条件查询时页码为0
    params = filterParams(values);
    getOrderList(params, 1);
    setCondition(params);
    setCurrent(1);
  };
  const getOrderList = (params, page) => {
    setLoading(true);
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = limit;
    axios
      .post(noBillSupplementListApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.data && res.data.err === 0) {
          const { orders, pagination } = res.data.data;

          setTotal(pagination.total);
          setData(orders);
        }
      });
    setLoading(false);
  };
  const pageChange = (value) => {
    setCurrent(value);
    getOrderList(condition, value);
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
          console.log(newObj, "newObj");
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  useEffect(() => {
    const values = form.getFieldsValue();
    // 获取用户渠道下拉值
    getChannelList();
    handleSubmit(values);
  }, []);
  // 禁用今天之前的日期
  const disabledDate = (current) => {
    return current && current >= moment().endOf("day");
  };
  const columns = [
    {
      title: "订单编号",
      dataIndex: "order_id",
      key: "order_id",
      render: (_, record) => (
        <div>
          <div>
            {record.order_id}
            {record.supplement === 1 && (
              <Tag color="purple" style={{ marginLeft: "10px" }}>
                已补单
              </Tag>
            )}
          </div>
        </div>
      ),
      width: 160,
    },
    {
      title: "用户ID",
      dataIndex: "user_id",
      key: "user_id",
      width: 100,
      render: (_, record) => (
        <Link
          to={`/user/detail?userId=${record.user_id}&channel=${record.channel}`}
        >
          {record.user_id}
        </Link>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "sku_name",
      key: "sku_name",
      width: 100,
      render: (_, record) => <div>{record.sku_name}</div>,
    },
    {
      title: "用户名称",
      dataIndex: "nick_name",
      key: "nick_name",
      width: 200,
    },
    {
      title: "用户渠道",
      dataIndex: "channel",
      key: "channel",
      width: 200,
      render: (_, record) => (
        <div>
          {channelObj[record.channel]
            ? channelObj[record.channel]
            : record.channel}
        </div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "补单原因",
      dataIndex: "supplement_remarks",
      key: "supplement_remarks",
      width: 140,
      render: (_, record) => <div>{record.supplement_remarks}</div>,
    },
  ];
  return (
    <Spin spinning={loading}>
      <div>
        <Form
          form={form}
          className="form"
          onFinish={handleSubmit}
          initialValues={{
            // sku_type: "all",
            channel: "wechat",
            status: "all",
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item label="用户渠道" name="channel">
                <Select placeholder="请选择用户渠道">
                  {channelList &&
                    channelList.map((item) => (
                      <Option value={item.channel} key={item.label}>
                        {item.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="商品类型"
                name="sku_type"
                rules={[
                  {
                    required: true,
                    message: "请输入商品类型",
                  },
                ]}
              >
                <Select style={{ width: 175 }} placeholder="请输入商品类型">
                  {GOODS_TYPE.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                label="订单状态"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "请输入商品类型",
                  },
                ]}
              >
                <Select style={{ width: 175 }} placeholder="请输入商品类型">
                  {ORDER_STATUS.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item name="user_id" label="用户ID">
                <Input placeholder="请输入用户ID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nick_name" label="用户名称">
                <Input placeholder="请输入用户名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="订单时间" name="time">
                <RangePicker disabledDate={disabledDate} locale={locale} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="styButton">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "140" }}
              >
                查询
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Table
          className="table-wrapped"
          rowKey={() => Math.random()}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            y: 280,
            x: 100,
          }}
        />
        {data && (
          <div className="pagination">
            <Pagination
              current={current}
              total={total}
              defaultPageSize={10}
              onChange={(page) => pageChange(page)}
              showSizeChanger={false}
              showTotal={(total) =>
                `共 ${total} 个订单 第 ${current} / ${Math.ceil(total / 10)} 页`
              }
            />
          </div>
        )}
      </div>
    </Spin>
  );
}
