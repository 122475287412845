import React from "react";
import axios from "axios";
import { filterParams, PALTFORMFILTER } from "../../../config/constant";
import {
  Form,
  Row,
  Col,
  message,
  Button,
  Input,
  InputNumber,
  Select,
} from "antd";
import WrappedModal from "../../components/WrappedModal/Modal";
import { streamerEditApi } from "../../../api/api";
import "./Edit.scss";
const { Option } = Select;
export default function AnchorDetailModal(props) {
  const { nickname, platform, url, description, weight, room, streamer_id } =
    props.record;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    let data = {};
    data = filterParams(values);
    data.token = localStorage.getItem("token");
    data.streamer_id = streamer_id;
    axios.post(streamerEditApi, { ...data }).then((res) => {
      if (res.data && res.data.err === 0) {
        message.success("修改主播信息成功");
        // 刷新列表
        props.initList();
      } else {
        message.error("修改主播信息失败");
      }
      props.cancel();
    });
  };
  return (
    <WrappedModal
      width={700}
      centered
      title="编辑主播信息"
      open={props.editAnchorModal}
      onCancel={props.cancel}
      forceRender={true}
      footer={null}
    >
      <>
        <Form
          onFinish={onFinish}
          form={form}
          className="form"
          initialValues={{
            nickname,
            platform,
            description,
            url,
            weight,
            room,
          }}
        >
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item
                name="nickname"
                label="主播名称"
                rules={[
                  {
                    required: true,
                    message: "请填写主播昵称",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item
                label="直播平台"
                name="platform"
                rules={[
                  {
                    required: true,
                    message: "请选择直播平台",
                  },
                ]}
              >
                <Select placeholder="请选择直播平台" style={{ width: 175 }}>
                  {PALTFORMFILTER.map((item) => (
                    <Option
                      key={item.label}
                      value={item.value}
                      disabled={item.value === "steam"}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item
                name="room"
                label="房间号"
                rules={[
                  {
                    required: true,
                    message: "请填写房间号",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item name="url" label="直播间链接">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item name="weight" label="权重">
                <InputNumber placeholder="请输入权重值" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item name="description" label="备注">
                <Input maxLength={100} placeholder="请输入备注信息" />
              </Form.Item>
            </Col>
          </Row>
          <div className="bottomBtn">
            <Button
              onClick={() => props.cancel()}
              size="large"
              style={{ width: "140" }}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
            >
              修改
            </Button>
          </div>
        </Form>
      </>
    </WrappedModal>
  );
}
