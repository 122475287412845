import React, { useEffect, useState } from "react";
import qs from "qs";
import axios from "axios";
import {
  EFFECT,
  ALL_CHANNEL,
  DEVICE_TYPE_CREATE,
} from "../../../config/constant";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Row, Col, message, Button } from "antd";
import { appIvfGetApi, appIvfEnableApi, channelApi } from "../../../api/api";
import { useLocation } from "react-router-dom";
const { confirm } = Modal;
export default function AppVersionDetail() {
  const location = useLocation();
  const [versionInfo, setVersionInfo] = useState({});
  const [channelObj, setChannelObj] = useState({});
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getVersionInfo = () => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    if (params.editionId) {
      allParams.edition_id = Number(params.editionId);
    }
    axios
      .post(appIvfGetApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const { ivf } = res.data.data;
          setVersionInfo(ivf);
        } else {
          message.error("获取版本信息详情失败");
        }
      });
  };
  useEffect(() => {
    // getChannelList();
    getVersionInfo();
  }, []);
  const getChannelList = async () => {
    const newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          console.log(newArr, newObj);
          setChannelObj(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const updateDta = (id, status) => {
    confirm({
      title: "更改版本状态",
      icon: <ExclamationCircleOutlined />,
      content: `确认更改当前版本状态为${EFFECT[status]}？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        updateItem(id, status);
      },
    });
  };
  const updateItem = (id, status) => {
    axios
      .post(appIvfEnableApi, {
        token: localStorage.getItem("token"),
        edition_id: Number(id),
        status,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("更改版本状态成功");
        } else {
          message.error("更改版本状态失败");
        }
        getVersionInfo();
      });
  };
  return (
    <div>
      <div className="userInfo">
        <div className="topTitle">版本详情</div>
        <Form className="form">
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="edition_id" label="版本号">
                <div className="edition_id right">
                  {versionInfo.edition_id ? versionInfo.edition_id : ""}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="status" label="状态">
                <span className="right"> {EFFECT[versionInfo.status]}</span>

                <span style={{ marginLeft: "20px" }} />
                {versionInfo.status === 0 && (
                  <Button
                    onClick={() => {
                      updateDta(versionInfo.edition_id, 1);
                    }}
                    type="link"
                  >
                    禁用
                  </Button>
                )}
                {versionInfo.status === 1 && (
                  <Button
                    onClick={() => {
                      updateDta(versionInfo.edition_id, 0);
                    }}
                    type="link"
                  >
                    启用
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>

          {versionInfo.app_versions &&
            Object.keys(versionInfo.app_versions).map((item) => {
              console.log(item, "item");
              return (
                <Row key={item}>
                  <Col span={1} />
                  <Col span={23}>
                    <Form.Item
                      name={`${versionInfo.app_versions[item]}`}
                      label={`${ALL_CHANNEL[item]}`}
                    >
                      <div className="right">
                        {versionInfo.app_versions[item]}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}

          <Row>
            <Col span={1} />
            <Col span={22}>
              <Form.Item name="url" label="IVF地址">
                <div
                  className="right msgInfo"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {versionInfo.url}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={22}>
              <Form.Item name="description" label="版本描述">
                <div
                  className="right msgInfo"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {versionInfo.description ? versionInfo.description : "-"}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
