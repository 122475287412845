import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  filterParams,
  DEVICE_TYPE_CREATE,
  ALL_CHANNEL,
} from "../../../config/constant";
import cloneDeep from "lodash/cloneDeep";

import {
  Form,
  Row,
  Col,
  message,
  Button,
  Input,
  Select,
  Divider,
  Space,
} from "antd";
import WrappedModal from "../../components/WrappedModal/Modal";
import {
  appIvfUpdateApi,
  channelApi,
  appversionListVersionOnlyApi,
} from "../../../api/api";
import { PlusOutlined } from "@ant-design/icons";

export default function AppEditModal(props) {
  const { platform, url, description, status, edition_id, app_versions } =
    props.record;
  const [name, setName] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [newSelectobj, setNewSelectobj] = useState({});
  const inputRef = useRef(null);
  const [form] = Form.useForm();

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e, channel) => {
    const obj = cloneDeep(newSelectobj);
    let newName = /^[\.\d]*$/;
    e.preventDefault();
    if (!name) {
      message.error("输入框不可为空");
      return;
    }
    if (newName.test(name)) {
      if (obj[channel]) {
        obj[channel] = [...obj[channel], name];
      } else {
        obj[channel] = [name];
      }
      setNewSelectobj(obj);
    } else {
      message.error("输入的字符只能包含数字和小数点");
      return;
    }
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  useEffect(() => {
    getChannelList();
    getSelectList();
  }, []);
  const getSelectList = async () => {
    await axios
      .post(appversionListVersionOnlyApi, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setNewSelectobj(res.data.data.appversions);
        } else {
          message.error("获取app版本信息失败");
        }
      });
  };
  const getChannelList = async () => {
    const newArr = [];
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const onFinish = (values) => {
    let data = {};
    data = filterParams(values);
    data.token = localStorage.getItem("token");
    data.edition_id = edition_id;
    axios.post(appIvfUpdateApi, { ...data }).then((res) => {
      if (res.data && res.data.err === 0) {
        message.success("修改版本信息成功");
        // 刷新列表
        props.initList();
      } else {
        message.error("修改版本信息失败");
      }
      props.cancel();
    });
  };
  return (
    <WrappedModal
      width={700}
      centered
      title="编辑版本信息"
      open={props.editAnchorModal}
      onCancel={props.cancel}
      forceRender={true}
      footer={null}
    >
      <>
        <Form
          onFinish={onFinish}
          form={form}
          className="form"
          dtyle={{ height: 400, overflowY: "auto" }}
          initialValues={{
            edition_id,
            platform,
            description,
            url,
            status,
            app_versions,
          }}
        >
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item
                name="edition_id"
                label="版本号"
                rules={[
                  {
                    required: true,
                    message: "请填写版本号",
                  },
                ]}
              >
                {edition_id}
              </Form.Item>
            </Col>
          </Row>

          {Object.keys(app_versions).map((item, index) => {
            return (
              <Row>
                <Col span={2} />
                <Col>
                  <Form.Item
                    name={["app_versions", `${item}`]}
                    // label={`${ALL_CHANNEL[item]}`}
                    label={`${ALL_CHANNEL[item] ? ALL_CHANNEL[item] : item}`}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "请选择系统来源",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder={`请选择ace项目中兼容${item.label}最低版本号`}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (option?.key ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={(e) => addItem(e, item)}
                            >
                              未来版本
                            </Button>
                          </Space>
                        </>
                      )}
                      options={
                        newSelectobj[item] &&
                        newSelectobj[item].map((item) => ({
                          label: item,
                          value: item,
                        }))
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item name="description" label="描述">
                <Input maxLength={100} placeholder="请输入描述信息" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="bottomBtn">
            <Button
              onClick={() => props.cancel()}
              size="large"
              style={{ width: "140" }}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
            >
              修改
            </Button>
          </div>
        </Form>
      </>
    </WrappedModal>
  );
}
