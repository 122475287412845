import { Navigate } from "react-router-dom";

function AuthComponent({ children }) {
  const isToken = localStorage.getItem("token");
  if (isToken) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
}
export { AuthComponent };
