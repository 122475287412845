import moment from "moment";
import hug from "../assets/images/hug.png";
import heart from "../assets/images/heart.png";
import praise from "../assets/images/praise.png";
import smile from "../assets/images/smile.png";
import cloneDeep from "lodash/cloneDeep";
export const USER_STATUS = [
  { label: "正常", value: 0 },
  { label: "封禁", value: 1 },
];

export const DEVICE_TYPE = [
  { label: "iOS", value: "ios" },
  { label: "Android", value: "android" },
  { label: "Steam", value: "steam" },
];
export const DEVICE_TABLE = {
  ios: "iOS",
  android: "Android",
  steam: "Steam",
};
export const DEVICE_ARR = ["ios", "android", "steam"];
export const DEVICE_TYPE_CREATE = [
  { label: "iOS", value: "ios" },
  { label: "Android", value: "android" },
];

export const ORDER_STATUS = [
  { label: "交易失败", value: "FAILED" }, // failure or closed
  { label: "未支付", value: "UNPAID" }, // order created, not paid yet
  { label: "交易成功", value: "SUCCESS" },
  { label: "已付款未发货", value: "ACKNOWLEDGED" }, // notify received, paid, goods not delivered yet
  { label: "已退款未退货", value: "REFACK" }, // ack, but refund version
  { label: "退款", value: "REFUND" }, // refunded
  { label: "全部", value: "all" },
];
export const GOODS_TYPE = [
  { label: "鲜花", value: "flower" },
  { label: "鸡蛋", value: "egg" },
  // { label: "钻石", value: "gameCurrency" },
  { label: "章节", value: "chapter" },
  { label: "全部", value: "all" },
];
export const GOODS_SUPPLEMENT = [
  { label: "鲜花", value: "flower" },
  { label: "鸡蛋", value: "egg" },
  // { label: "钻石", value: "gameCurrency" },
  { label: "章节", value: "chapter" },
];
export const BUY_USE_TYPE = {
  rmb: "购买",
  gameCurrency: "钻石",
  reward: "奖励",
  use: "使用",
  supplement: "补单",
  all: "全部",
};
export const WAY = [
  { label: "购买", value: "rmb" },
  { label: "奖励", value: "reward" },
  { label: "使用", value: "use" },
  { label: "补单", value: "supplement" },
  // { label: "钻石", value: "gameCurrency" },
  // { label: "点数", value: "point" },
];
export const USER_INFO_DEVICE = {
  wechat: "微信",
  qq: "QQ",
  steam: "demo版",
  steam_prod: "正式版",
  all: "全部",
};
export const ALL_CHANNEL = {
  android: "安卓",
  wechat: "微信",
  qq: "QQ",
  douyin: "抖音",
  steam: "steam demo版",
  steam_prod: "steam正式版",
  huawei: "华为",
  taptap: "TapTap",
  apple: "苹果",
  bilibili: "哔哩哔哩",
  altstory: "官网",
  xiaomi: "小米",
  my4399: "4399",
  apple_ww: "苹果全球",
};
export const USER_INFO_STATUS = { 0: "正常", 1: "禁用" };
export const USER_TYPE = [
  { label: "微信", value: "wechat" },
  { label: "QQ", value: "qq" },
  { label: "steam", value: "steam" },
];
export const INVENTORY_INFO = {
  flower: "鲜花",
  egg: "鸡蛋",
  gameCurrency: "钻石",
  chapter: "章节",
  free: "有序章",
  gratitude: "上半部（感恩篇)",
  fight: "下半部（反击篇)",
  full: "完整版",
};
// 交易状态
export const PAY_STATUS = {
  SUCCESS: "交易成功",
  // CANCEL: "超时取消",
  UNPAID: "未付款",
  FAILED: "交易失败",
  ACKNOWLEDGED: "商品未发放",
  REFUND: "退款",
  REFACK: "",
};
export const PLATTABLE = {
  bilibili: "B站",
  douyin: "抖音",
  douyu: "斗鱼",
  kuaishou: "快手",
};
export const PALTFORM = [
  { value: "bilibili", label: "B站" },
  { value: "douyin", label: "抖音" },
  { value: "douyu", label: "斗鱼" },
  { value: "kuaishou", label: "快手" },
  { value: "all", label: "全部" },
];
// 创建主播和编辑主播时不能选全部
export const PALTFORMFILTER = [
  { value: "bilibili", label: "B站" },
  { value: "douyin", label: "抖音" },
  { value: "douyu", label: "斗鱼" },
  { value: "kuaishou", label: "快手" },
];
export const AUDIT_STATUS = [
  { value: "all", label: "所有状态" },
  { value: "pending", label: "待定" },
  { value: "approved", label: "通过" },
  { value: "denied", label: "不通过" },
  { value: "blocked", label: "屏蔽" },
];
export const TABLE_AUDIT_STATUS = {
  pending: "待定",
  approved: "通过",
  denied: "不通过",
  blocked: "屏蔽",
};
export const EMOTION = {
  1: heart,
  2: smile,
  3: praise,
  4: hug,
};
export const ANCHOR_STATUS = [
  { value: "all", label: "全部" },
  { value: "blocked", label: "屏蔽" },
  { value: "unblocked", label: "正常" },
];

export const SORT = [
  { label: "发布时间", value: "created_at" },
  { label: "爱心最多", value: "emoji_1" },
  { label: "笑哭最多", value: "emoji_2" },
  { label: "点赞最多", value: "emoji_3" },
  { label: "拥抱最多", value: "emoji_4" },
];
// 过滤params中undefined数据
export const filterParams = (values) => {
  let params = {};
  Object.keys(values).forEach((item) => {
    if (values[item] === undefined || values[item] === "") {
      delete values[item];
    }
    if (values[item] || values[item] === 0 || values[item] === false) {
      if (item === "user_id") {
        params[item] = Number(cloneDeep(values["user_id"]));
      } else if (item === "id") {
        params[item] = Number(cloneDeep(values["id"]));
      } else {
        params[item] = values[item];
      }
    }
  });
  return params;
};
// 获取开始时间00：00，结束时间23:59:59
export const formatTime = (allParams) => {
  let startTime;
  let endTime;
  startTime =
    Math.trunc(Date.parse(allParams.time[0]) / (1000 * 60 * 60 * 24)) *
      (1000 * 60 * 60 * 24) -
    8 * 60 * 60 * 1000;
  if (
    allParams.time[1].format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
  ) {
    endTime = allParams.time[1];
  } else {
    endTime = Math.trunc(Date.parse(allParams.time[1]) / (1000 * 60 * 60 * 24));
    endTime =
      endTime * (1000 * 60 * 60 * 24) +
      1000 * 60 * 60 * 24 -
      1000 -
      8 * 60 * 60 * 1000;
  }
  return {
    startTime: moment(startTime).format(),
    endTime: moment(endTime).format(),
  };
};
// 下载链接
export const linkDownload = (url, fileName) => {
  var x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = function (e) {
    var url = window.URL.createObjectURL(x.response);
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  };
  x.send();
};
export const fiterIsAllParams = (values) => {
  Object.keys(values).forEach((item) => {
    if (values[item] === "all") {
      delete values[item];
    }
  });
  return values;
};
export const ACTIVATE_CHAPTER = [
  { label: "所有", value: -1 },
  { label: "全部章节", value: 0 },
  { label: "感恩篇", value: 1 },
  { label: "反击篇", value: 2 },
  { label: "Alpha测试", value: 3 },
];
// 创建激活码时排除全部
export const ACTIVATE_CHAPTER_EXA = [
  { label: "全部章节", value: 0 },
  { label: "感恩篇", value: 1 },
  { label: "反击篇", value: 2 },
  { label: "Alpha测试", value: 3 },
];
export const ACTIVATE_CHAPTER_TAB = {
  0: "全部章节",
  1: "感恩篇",
  2: "反击篇",
  3: "Alpha测试",
};
export const CODE_USER_STATUS = [
  { label: "禁用", value: 1 },
  { label: "已使用", value: 2 },
  { label: "未使用", value: 3 },
];
export const DISABLED_STATUS = [
  { label: "启用", value: 0 },
  { label: "禁用", value: 1 },
];
export const EFFECT = {
  0: "启用",
  1: "禁用",
};
export const APP_STATUS = [
  { label: "普通", value: "normal" },
  { label: "强制升级", value: "critical" },
  { label: "强制废除", value: "abolished" },
];
export const APP_VERSION_STATUS = {
  normal: "普通",
  critical: "强制升级",
  abolished: "强制废除",
};
// 用于渠道去重
export const distint = (arr) => {
  var newObj = {};
  var newArr = [];
  for (var i = 0; i < arr.length; i++) {
    var item = arr[i];
    if (!newObj[item.channel]) {
      newObj[item.channel] = newArr.push(item);
    }
  }
  return newArr;
};
