import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Switch,
} from "antd";
import axios from "axios";
import {
  filterParams,
  APP_STATUS,
  DEVICE_ARR,
  distint,
  DEVICE_TYPE_CREATE,
} from "../../../config/constant";
import { appVersionCreateApi, channelApi } from "../../../api/api";
const { Option } = Select;
export default function AppVersionCreate() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);

  const onFinish = (values) => {
    setLoading(true);
    let params = {};
    params = filterParams(values);
    createAppVersion(params);
  };
  useEffect(() => {
    getChannelList();
  }, []);

  const getChannelList = async () => {
    // 获取用户渠道下拉值
    let newArr = [];
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          newArr = distint(newArr);
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const createAppVersion = (params) => {
    let allParams = {};
    if (params) {
      allParams = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    axios
      .post(appVersionCreateApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("app版本创建成功");
          form.resetFields();
        } else if (res.data.err === 1009) {
          message.error("app版本号已存在");
        } else {
          message.error("app版本创建失败");
        }
        setLoading(false);
      });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        className="form"
        initialValues={{
          platform: "ios",
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="用户渠道"
              name="channel"
              rules={[
                {
                  required: true,
                  message: "请输入用户渠道",
                },
              ]}
            >
              <Select placeholder="请选择用户渠道">
                {channelList &&
                  channelList.map((item, index) => (
                    <Option key={index} value={item.channel}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="version"
              label="app版本号"
              rules={[
                {
                  required: true,
                  message: "请输入app版本号",
                },
              ]}
            >
              <Input placeholder="请输入app版本号" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="状态"
              name="status"
              rules={[
                {
                  required: true,
                  message: "请选择生效状态",
                },
              ]}
            >
              <Select placeholder="请选择生效状态">
                {APP_STATUS.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="url" label="商店链接">
              <Input placeholder="请输入商店链接" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="描述">
              <Input placeholder="请输入版本更新描述" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="styButton">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
              loading={loading}
            >
              创建
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
