import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./Login.scss";
import imgCover from "../../../assets/images/login-cover.png";
import { loginApi } from "../../../api/api";
import { setCookie, removeCookie, getCookie } from "../../../utils/utils";
const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isRember, setIsRember] = useState(false);
  useEffect(() => {
    remember();
  }, []);
  const onFinish = (values) => {
    axios
      .post(loginApi, {
        account: values.account,
        password: values.password,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          // 存储数据
          localStorage.setItem("account", values.account);
          localStorage.setItem("token", res.data.data.token);
          message.success("登录成功，即将进入用户列表页");
          setTimeout(() => {
            navigate("/user");
          }, 1000);
        }
      });
  };
  const remeberCount = (e) => {
    setIsRember(e.target.checked);
    if (e.target.checked) {
      const account = form.getFieldValue("account");
      const password = form.getFieldValue("password");
      setCookie("account", account, 7);
      setCookie("password", password, 7);
    } else {
      removeCookie("account");
      removeCookie("password");
    }
  };
  // 下一次登录将cookie填充表单
  const remember = () => {
    if (getCookie("account") !== "" && getCookie("password") !== "") {
      form.setFieldsValue({
        account: getCookie("account"),
        password: getCookie("password"),
      });
      setIsRember(true);
    }
  };
  return (
    <div className="login">
      <div className="login_box">
        <div className="login_left">
          <img src={imgCover} alt="ACE" />
        </div>
        <div className="login_right">
          <div className="login_top">账户登录</div>
          <div className="submit_form">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                // label="邮件账户"
                name="account"
                rules={[
                  {
                    required: true,
                    message: "请输入邮件账户!",
                  },
                ]}
              >
                <Input
                  className="form-input"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                // label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              >
                <Input.Password
                  className="form-input"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox onChange={remeberCount} checked={isRember}>
                  记住账号
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block size="large">
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
