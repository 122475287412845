import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Select,
  Table,
  Space,
  Modal,
  Row,
  Col,
  message,
  Pagination,
  InputNumber,
  Tooltip,
} from "antd";
import {
  appIvfEnableApi,
  appIvfListApi,
  appIvfGetApi,
  channelApi,
} from "../../../api/api";
import {
  filterParams,
  EFFECT,
  DISABLED_STATUS,
  DEVICE_TYPE_CREATE,
} from "../../../config/constant";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";
import AppEditModal from "./Edit";
const { confirm } = Modal;
const { Option } = Select;
export default function IVFVersionList() {
  const [form] = Form.useForm();
  const params = useParams();
  const [versionList, setVersionList] = useState([]);
  const [condition, setCondition] = useState({});
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [editAnchorModal, setEditAnchorModal] = useState(false);
  const [ivfInfo, setivfInfo] = useState({});
  const [channelList, setChannelList] = useState([]);
  const getVersionList = (params, page) => {
    let allParams = {};
    if (params) {
      allParams.filter = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = 10;
    axios.post(appIvfListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { ivfs, pagination } = res.data.data;
        setTotal(pagination.count);
        setCurrent(pagination.page);
        setVersionList(ivfs);
      } else {
        message.error("获取ivf版本列表失败");
      }
    });
  };
  const getChannelList = async () => {
    const newArr = [];
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const initList = (page) => {
    const values = form.getFieldsValue();
    onFinish(values, page);
  };
  useEffect(() => {
    initData();
    // getChannelList();
  }, []);
  const initData = () => {
    const values = form.getFieldsValue();
    onFinish(values);
  };
  const onFinish = (values) => {
    let params = {};
    params = filterParams(values);
    getVersionList(params, 1);
    setCondition(params);
  };
  const updateDta = (id, status) => {
    confirm({
      title: "更改版本状态",
      icon: <ExclamationCircleOutlined />,
      content: `确认更改当前版本状态为${EFFECT[status]}？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        updateItem(id, status);
      },
    });
  };
  const updateItem = (id, status) => {
    axios
      .post(appIvfEnableApi, {
        token: localStorage.getItem("token"),
        edition_id: Number(id),
        status,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("更改版本状态成功");
        } else {
          message.error("更改版本状态失败");
        }
        initData();
      });
  };
  const isDeleteItem = () => {
    confirm({
      title: "删除版本记录",
      icon: <ExclamationCircleOutlined />,
      content: "确认删除当前版本？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        deleteItem();
      },
    });
  };
  const deleteItem = () => {
    axios
      .post(appIvfGetApi, {
        ...params,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const values = form.getFieldsValue();
          if (params.id) {
            onFinish({ ...values, id: params.id });
          } else {
            onFinish(values);
          }
        } else {
          message.error("删除数据失败");
        }
      });
  };
  const columns = [
    {
      title: "版本号",
      dataIndex: "edition_id",
      key: "edition_id",
      render: (_, record) => (
        <Link to={`/ivfList/detail?editionId=${record.edition_id}`}>
          {record.edition_id}
        </Link>
      ),
      width: 150,
    },
    {
      title: "版本状态",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (_, record) => <div>{EFFECT[record.status]}</div>,
    },
    {
      title: "渠道支持最低版本号",
      dataIndex: "app_versions",
      width: 320,
      key: "app_versions",
      render: (_, record) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.keys(record.app_versions).map((version) => {
            return (
              record.app_versions[version] && (
                <div key={version} style={{ marginRight: 20, width: 120 }}>
                  <Link
                    to={`/appList/detail?versionId=${record.app_versions[version]}&channel=${version}&editionId=${record.edition_id}`}
                  >
                    <span id={version}>
                      <span>{version}</span>:
                      <span>{record.app_versions[version]}</span>
                    </span>
                  </Link>
                </div>
              )
            );
          })}
        </div>
      ),
    },
    {
      title: "IVF地址",
      dataIndex: "url",
      key: "url",
      width: 300,
      render: (_, record) => <span>{record.url}</span>,
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      width: 180,
      render: (_, record) => {
        const subDes = record.description.substr(0, 8) + "...";
        return (
          <div>
            {record.description.length > 10 ? (
              <Tooltip
                placement="topLeft"
                title={record.description}
                overlayClassName="titleOverSty"
              >
                <div>{subDes}</div>
              </Tooltip>
            ) : (
              <div>{record.description ? record.description : "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "操作",
      fixed: "right",
      key: "operate",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {record.status === 0 && (
            <Button
              onClick={() => {
                setivfInfo(record);
                updateDta(record.edition_id, 1);
              }}
              type="link"
            >
              禁用
            </Button>
          )}
          {record.status === 1 && (
            <Button
              onClick={() => {
                setivfInfo(record);
                updateDta(record.edition_id, 0);
              }}
              type="link"
            >
              启用
            </Button>
          )}
          <Button
            onClick={() => {
              setivfInfo(record);
              setEditAnchorModal(true);
            }}
            type="link"
          >
            编辑
          </Button>
          {/* <Button type="link" danger onClick={isDeleteItem}>
            删除
          </Button> */}
        </Space>
      ),
    },
  ];
  const pageChange = (value) => {
    setCurrent(value);
    getVersionList(condition, value);
  };
  return (
    <div>
      <Form
        form={form}
        className="form"
        onFinish={onFinish}
        // initialValues={{
        //   status: 0,
        // }}
      >
        <Row>
          <Col span={12}>
            <Form.Item name="edition_id" label="版本号">
              <InputNumber
                min={0}
                placeholder="请输入ivf版本号"
                style={{ width: "140" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="版本状态" name="status">
              <Select
                placeholder="请输入版本状态"
                style={{ borderRadius: "5px" }}
              >
                {DISABLED_STATUS.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" width={140}>
              查询
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <Table
        rowKey={(record) => record.edition_id}
        columns={columns}
        dataSource={versionList}
        pagination={false}
        scroll={{
          y: 520,
        }}
      />
      {versionList && (
        <div className="pagination">
          <Pagination
            current={current}
            total={total}
            onChange={(page) => pageChange(page)}
            showSizeChanger={false}
            showTotal={(total) =>
              `共 ${total} 个版本 第 ${current} / ${Math.ceil(total / 10)} 页`
            }
          />
        </div>
      )}
      {editAnchorModal && (
        <AppEditModal
          editAnchorModal={editAnchorModal}
          record={ivfInfo}
          initList={initList}
          cancel={() => setEditAnchorModal(false)}
        />
      )}
    </div>
  );
}
