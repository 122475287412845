import React, { useEffect, useState } from "react";
import qs from "qs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { Form, Modal, Switch, Row, Col, message, Button, List } from "antd";
import {
  streamerGetApi,
  commentListApi,
  streamerBlockApi,
} from "../../../api/api";
import { useNavigate, useLocation } from "react-router-dom";
import "./Detail.scss";
const { confirm } = Modal;
export default function AnchorDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [anchorInfo, setAnchorInfo] = useState({});
  const [streamerId, setStreamerId] = useState();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filterMessage, setFilterMessage] = useState([]);
  const [total, setTotal] = useState(0);
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getDisabled = (status) => {
    axios
      .post(streamerBlockApi, {
        token: localStorage.getItem("token"),
        status: status,
        streamer_id: streamerId,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          status === "blocked"
            ? message.success("屏蔽成功")
            : message.success("解除屏蔽成功");
        } else {
          status === "blocked"
            ? message.success("屏蔽成功")
            : message.success("解除屏蔽成功");
        }
        getAnchorInfo(streamerId);
      });
  };
  const getMessageList = (streamer_id, page) => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    if (page === 1) {
      allParams.pagination.need_total = true;
    }
    allParams.pagination.page = page;
    allParams.pagination.limit = 5;
    allParams.filter = {};
    allParams.filter.streamer_id = streamer_id;
    axios.post(commentListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { results, total } = res.data.data;
        if (total && total.results) {
          setTotal(total.results);
        }
        setFilterMessage(filterMessage.concat(results));
      } else {
        message.error("获取留言列表失败");
      }
      setLoading(false);
      setInitLoading(false);
    });
  };
  const getAnchorInfo = (streamerId) => {
    axios
      .post(streamerGetApi, {
        token: localStorage.getItem("token"),
        streamer_id: streamerId,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setAnchorInfo(res.data.data.streamer);
        } else {
          message.error("获取列表失败");
        }
      });
  };
  const changeSwitch = (e) => {
    if (anchorInfo.status === "unblocked") {
      confirm({
        title: "账号封禁",
        icon: <ExclamationCircleOutlined />,
        content: "确认封禁当前主播？",
        okText: "封禁",
        cancelText: "取消",
        onOk() {
          getDisabled("blocked");
        },
      });
    } else {
      confirm({
        title: "账号恢复",
        icon: <ExclamationCircleOutlined />,
        content: "确认恢复当前主播吗？",
        okText: "恢复",
        cancelText: "取消",
        onOk() {
          getDisabled("unblocked");
        },
      });
    }
  };
  useEffect(() => {
    if (params.streamer_id) {
      const streamer_id = Number(params.streamer_id);
      // 调取当前用户详情信息接口
      getAnchorInfo(streamer_id);
      setStreamerId(streamer_id);
      // 获取主播内容
      getMessageList(streamer_id, 1);
    }
  }, []);
  const lookDetail = (item) => {
    navigate(
      `/messageList/detail?comment_id=${item.comment_id}&streamer_id=${streamerId}`
    );
  };
  const onLoadMore = () => {
    setPage(page + 1);
    if (params.streamer_id) {
      const streamer_id = Number(params.streamer_id);
      getMessageList(streamer_id, page + 1);
      setLoading(true);
    }
  };
  const loadMore =
    !initLoading && !loading && total !== filterMessage.length ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} type="primary">
          加载更多
        </Button>
      </div>
    ) : null;
  return (
    <div>
      <div className="userInfo">
        <div className="topTitle">
          <div className="headerBtn">
            <div> 主播信息</div>
            {/* <Button>屏蔽主播</Button> */}
          </div>
        </div>

        <Form
          className="form"
          // initialValues={{
          //   status: anchorInfo.status,
          // }}
        >
          <Row>
            <Col span={1} />
            <Form.Item name="nickname" label="主播昵称">
              <div className="right"> {anchorInfo.nickname}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="platform" label="直播平台">
              <div className="right"> {anchorInfo.platform || "-"}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="room" label="主播房间号">
              <div className="right">{anchorInfo.room}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="url" label="直播间链接">
              <div className="right">
                {anchorInfo.url ? anchorInfo.url : "-"}
              </div>
            </Form.Item>
          </Row>

          {/* <Row>
            <Col span={1} />
            <Form.Item name="weight" label="权重">
              <div className="right">{anchorInfo.weight}</div>
            </Form.Item>
          </Row> */}
          <Row>
            <Col span={1} />
            <Form.Item name="status" label="主播状态">
              <div className="right">
                <Switch
                  checked={anchorInfo.status === "unblocked"}
                  checkedChildren="正常"
                  unCheckedChildren="屏蔽"
                  onChange={() => changeSwitch(anchorInfo.status)}
                />
              </div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="description" label="备注">
              <div className="right">{anchorInfo.description}</div>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <div className="prodInfo">
        <div className="topTitle">留言内容</div>
        <div className="msg-content">
          <List
            className="demo-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={filterMessage}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    key="list-loadmore-edit"
                    type="link"
                    onClick={() => lookDetail(item)}
                  >
                    查看
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={`内容${index + 1}`}
                  description={item.content ? item.content : "-"}
                />
              </List.Item>
            )}
          />
        </div>
        {/* <List
          grid={{
            gutter: 16,
            column: 4,
          }}
          dataSource={content}
          renderItem={(item) => (
            <List.Item>
              <Card title={JSON.stringify(item)}>{JSON.stringify(item)}</Card>
            </List.Item>
          )}
        /> */}
      </div>
    </div>
  );
}
