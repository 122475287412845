import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  Space,
  Pagination,
  InputNumber,
  Modal,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  filterParams,
  formatTime,
  linkDownload,
  DEVICE_TABLE,
  ACTIVATE_CHAPTER,
  ACTIVATE_CHAPTER_TAB,
  ALL_CHANNEL,
  DEVICE_ARR,
  distint,
} from "../../../config/constant";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import { cdkeyListApi, channelApi } from "../../../api/api";
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function CDKeyList() {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [system, setSystem] = useState("");
  const [condition, setCondition] = useState();
  const [limit, setLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [channelObj, setChannelObj] = useState({});
  const getCodeList = (params, page) => {
    setLoading(true);
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = limit;
    axios
      .post(cdkeyListApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const { bunches, pagination } = res.data.data;
          setList(bunches);
          setTotal(pagination.total);
        }
      });
    setLoading(false);
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          setChannelList(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const onFinish = (values) => {
    let params = {};
    params = filterParams(values);
    getCodeList(params, 1);
    setCurrent(1);
    setCondition(params);
  };
  const pageChange = (page) => {
    setCurrent(page);
    getCodeList(condition, page);
  };
  const initialTable = (params) => {
    const values = form.getFieldsValue();
    // 排序的参数
    if (params) {
      onFinish({ ...values, ...params });
    } else {
      onFinish(values);
    }
  };
  useEffect(() => {
    initialTable();
    getChannelList();
  }, []);
  const clickTxt = (urls, title) => {
    linkDownload(urls, title);
  };
  const disabledDate = (current) => {
    return current && current >= moment().endOf("day");
  };
  const changeSource = (e) => {
    setSystem(e);
    form.setFieldValue("channel", "");
  };
  const columns = [
    {
      title: "激活码ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (_, record) => (
        <Link to={`/list/detail?id=${record.id}`}>{record.id}</Link>
      ),
    },
    {
      title: "激活码数量",
      dataIndex: "number",
      key: "number",
      width: 120,
    },
    {
      title: "内容",
      dataIndex: "sub_id",
      key: "sub_id",
      width: 120,
      render: (_, record) => <div>{ACTIVATE_CHAPTER_TAB[record.sub_id]}</div>,
    },
    {
      title: "已用",
      dataIndex: "number_of_used",
      key: "number_of_used",
      width: 80,
      sorter: () => {},
    },
    {
      title: "可用",
      dataIndex: "number_of_available",
      key: "number_of_available",
      width: 90,
      sorter: () => {},
    },
    {
      title: "禁用",
      dataIndex: "number_of_banned",
      key: "number_of_banned",
      width: 80,
      sorter: () => {},
    },
    {
      title: "用户渠道",
      dataIndex: "channel",
      key: "channel",
      width: 200,
      render: (_, record) => (
        <div>
          {record.channel
            ? channelObj[record.channel]
              ? channelObj[record.channel]
              : record.channel
            : "-"}
        </div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 180,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      width: 140,
      render: (_, record) => <div>{record.remark ? record.remark : "-"}</div>,
    },
    {
      width: 240,
      title: "操作",
      key: "operate",
      fixed: "right",
      render: (_, record) => (
        <>
          <Space size="middle" style={{ marginRight: "10px" }}>
            <Link to={`/list/detail?id=${record.id}`}>查看</Link>
          </Space>
          <Space
            size="middle"
            onClick={() =>
              clickTxt(
                record.oss_url,
                record.remark
                  ? record.remark
                  : moment(new Date()).format("YYYY-MM-DD") + "激活码"
              )
            }
          >
            <Button type="link">下载文件</Button>
          </Space>
        </>
      ),
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.column.dataIndex) {
      let asc = "";
      if (sorter.order === "ascend") {
        asc = true;
      } else if (sorter.order === "descend") {
        asc = false;
      }
      const params = {
        sort: {},
      };
      params.sort.field = sorter.column.dataIndex
        ? sorter.column.dataIndex
        : "";
      params.sort.asc = asc;
      initialTable(params);
    } else {
      initialTable();
    }
  };
  return (
    <div>
      <Spin spinning={loading}>
        <div className="top">
          <Form
            form={form}
            className="form"
            onFinish={onFinish}
            initialValues={{
              // device_type: "all",
              channel: "android",
              chap: -1,
            }}
          >
            <Row>
              {/* <Col span={10}>
                <Form.Item name="codes" label="激活码">
                  <TextArea
                    rows={6}
                    style={{
                      width: "200px",
                      borderRadius: "5px",
                      minHeight: "160px",
                    }}
                    placeholder="请输入激活码，可批量输入，并用“,”隔开"
                  />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  label="用户渠道"
                  name="channel"
                  rules={[
                    {
                      required: true,
                      message: "请输入用户渠道",
                    },
                  ]}
                >
                  <Select placeholder="请选择用户渠道">
                    {channelList &&
                      channelList.map((item) => (
                        <Option value={item.channel} key={item.label}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="内容"
                  name="sub_id"
                  rules={[
                    {
                      required: true,
                      message: "请输入内容",
                    },
                  ]}
                >
                  <Select placeholder="请输入内容" style={{ width: 175 }}>
                    {ACTIVATE_CHAPTER.map((item) => (
                      <Option key={item.label} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="remark" label="批次备注">
                  <Input placeholder="输入批次备注：2023核聚变" style={{ width: "140" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="创建时间" name="time">
                  <RangePicker
                    disabledDate={disabledDate}
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="styButton">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: "140" }}
                >
                  查询
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
        <div>
          <Table
            className="table-wrapped"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={list}
            pagination={false}
            onChange={onChange}
            scroll={{
              y: 330,
            }}
          />
          {list && (
            <div className="pagination">
              <Pagination
                current={current}
                total={total}
                defaultPageSize={10}
                onChange={(page) => pageChange(page)}
                showSizeChanger={false}
                showTotal={(total) =>
                  `共 ${total} 份激活码 第 ${current} / ${Math.ceil(
                    total / 10
                  )} 页`
                }
              />
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
}
