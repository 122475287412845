import React, { useState } from "react";
import axios from "axios";
import { Form, Row, Col, message, InputNumber } from "antd";
import WrappedModal from "../../components/WrappedModal/Modal";
import { commentReactionApi } from "../../../api/api";
export default function ReactModal(props) {
  const { comment_id, emoji_id, forged_emoji, channel } = props.record;
  const [form] = Form.useForm();
  const [info, setInfo] = useState();

  const updateMessageInfo = (info) => {
    axios
      .post(commentReactionApi, {
        token: localStorage.getItem("token"),
        comment_id,
        emoji_id,
        forged_amount: info,
        channel,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("修改表情数量成功");
        } else if (res.data.err === 1020) {
          message.error("严重错误，建议联系技术团队");
        } else {
          message.error("修改表情数量失败");
        }
        // 刷新留言详情列表
        props.initInfoData();
      });
  };
  const handleOk = () => {
    updateMessageInfo(info);

    props.cancel();
  };
  const changeValue = (value) => {
    setInfo(value);
  };
  return (
    <WrappedModal
      title="修改互动表情包"
      open={props.reactionModalVisival}
      onOk={handleOk}
      onCancel={props.cancel}
      forceRender={true}
      okText="保存"
      cancelText="取消"
    >
      <>
        <Form
          form={form}
          className="form"
          initialValues={{
            forged_amount: forged_emoji,
          }}
        >
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item name="comment_id" label="消息ID">
                <div>{comment_id}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item name="emoji_id" label="表情ID">
                <div>{emoji_id}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={12}>
              <Form.Item
                name="forged_amount"
                label="表情数量"
                rules={[
                  {
                    required: true,
                    message: "请输入表情数量",
                  },
                ]}
              >
                <InputNumber min={0} max={10000} onChange={changeValue} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    </WrappedModal>
  );
}
