import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Select,
  Table,
  Space,
  Modal,
  Row,
  Col,
  message,
  Pagination,
  Switch,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { streamerListApi, streamerBlockApi } from "../../../api/api";
import {
  filterParams,
  fiterIsAllParams,
  PALTFORM,
  ANCHOR_STATUS,
  PLATTABLE,
} from "../../../config/constant";
import AnchorDetailModal from "./Edit";
import { Link } from "react-router-dom";
const { confirm } = Modal;
const { Option } = Select;
export default function AnchorList() {
  const [form] = Form.useForm();
  const [anchorList, setAnchorLit] = useState([]);
  const [condition, setCondition] = useState({});
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [editAnchorModal, setEditAnchorModal] = useState(false);
  const [anchorInfo, setAnchorInfo] = useState({});
  const getAnchorList = (params, page) => {
    let allParams = {};
    if (params && JSON.stringify(params) !== "{}") {
      allParams.filter = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = 10;
    if (page === 1) {
      allParams.pagination.need_total = true;
    }
    axios.post(streamerListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { results, total } = res.data.data;
        setAnchorLit(results);
        if (total && total.results) {
          setTotal(total.results);
        }
        setCurrent(page);
      } else {
        message.error("获取主播列表失败");
      }
    });
  };
  const initList = (page) => {
    const values = form.getFieldsValue();
    onFinish(values, page);
  };
  useEffect(() => {
    initList();
  }, []);
  const onFinish = (values, page) => {
    let params = {};
    params = filterParams(values);
    params = fiterIsAllParams(values);
    if (params && params.user_id) {
      params.user_id = Number(params.user_id);
    }
    getAnchorList(params, page ? page : 1);

    setCondition(params);
  };
  const columns = [
    {
      title: "主播昵称",
      dataIndex: "nickname",
      key: "nickname",
      width: 150,
      render: (_, record) => (
        <div>
          <Link to={`/anchorList/detail?streamer_id=${record.streamer_id}`}>
            {record.nickname}
          </Link>
        </div>
      ),
    },

    {
      title: "直播平台",
      dataIndex: "platform",
      key: "platform",
      width: 120,
      render: (_, record) => <div>{PLATTABLE[record.platform]}</div>,
    },
    {
      title: "房间号",
      dataIndex: "room",
      key: "room",
      width: 120,
      render: (_, record) => <div>{record.room}</div>,
    },
    {
      title: "主播直播间",
      dataIndex: "url",
      key: "url",
      width: 250,
      render: (_, record) => <div>{record.url ? record.url : "-"}</div>,
    },
    {
      title: "备注",
      dataIndex: "description",
      key: "description",
      width: 150,
      render: (_, record) => (
        <div>{record.description ? record.description : "-"}</div>
      ),
    },
    {
      title: "权重",
      dataIndex: "weight",
      key: "weight",
      width: 120,
      render: (_, record) => <div>{record.weight}</div>,
    },
    // {
    //   title: "创建时间",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   width: 180,
    //   render: (_, record) => (
    //     <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
    //   ),
    // },
    {
      title: "操作",
      fixed: "right",
      key: "operate",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setAnchorInfo(record);
              setEditAnchorModal(true);
            }}
          >
            编辑
          </Button>
          <Switch
            checked={record.status === "unblocked"}
            checkedChildren="正常"
            unCheckedChildren="屏蔽"
            onChange={() => changeSwitch(record)}
          />
        </Space>
      ),
    },
  ];
  const pageChange = (value) => {
    setCurrent(value);
    getAnchorList(condition, value);
  };
  const getDisabled = (status, streamer_id) => {
    axios
      .post(streamerBlockApi, {
        token: localStorage.getItem("token"),
        status: status,
        streamer_id: streamer_id,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          status === "blocked"
            ? message.success("取消屏蔽成功")
            : message.success("屏蔽成功");
        } else {
          status === "blocked"
            ? message.success("取消屏蔽成功")
            : message.success("屏蔽成功");
        }
        initList(current);
      });
  };
  const changeSwitch = (record) => {
    if (record.status === "unblocked") {
      confirm({
        title: "屏蔽主播",
        icon: <ExclamationCircleOutlined />,
        content: "确认屏蔽当前主播吗？",
        okText: "屏蔽",
        cancelText: "取消",
        onOk() {
          getDisabled("blocked", record.streamer_id);
        },
      });
    } else {
      confirm({
        title: "解除屏蔽主播",
        icon: <ExclamationCircleOutlined />,
        content: "确认取消屏蔽当前主播吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          getDisabled("unblocked", record.streamer_id);
        },
      });
    }
  };
  return (
    <div>
      <Form
        form={form}
        className="form"
        onFinish={onFinish}
        initialValues={{
          status: "all",
          platform: "all",
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="主播状态"
              name="status"
              rules={[
                {
                  required: true,
                  message: "请选择主播状态",
                },
              ]}
            >
              <Select
                placeholder="请选择主播状态"
                style={{ borderRadius: "5px" }}
              >
                {ANCHOR_STATUS.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="直播平台"
              name="platform"
              rules={[
                {
                  required: true,
                  message: "请选择直播平台",
                },
              ]}
            >
              <Select
                placeholder="请选择直播平台"
                style={{ borderRadius: "5px" }}
              >
                {PALTFORM.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="主播昵称" name="nickname">
              <Input placeholder="请输入主播昵称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="主播房间号" name="room">
              <Input placeholder="请输入直播房间号" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="description" label="备注">
              <Input maxLength={100} placeholder="请输入备注信息" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" width={140}>
              查询
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <Table
        rowKey={(record) => record.streamer_id}
        columns={columns}
        dataSource={anchorList}
        pagination={false}
        scroll={{
          y: 340,
        }}
      />
      {anchorList && (
        <div className="pagination">
          <Pagination
            current={current}
            total={total}
            onChange={(page) => pageChange(page)}
            showSizeChanger={false}
            showTotal={(total) =>
              `共 ${total} 位主播 第 ${current} / ${Math.ceil(total / 10)} 页`
            }
          />
        </div>
      )}
      {editAnchorModal && (
        <AnchorDetailModal
          editAnchorModal={editAnchorModal}
          record={anchorInfo}
          initList={initList}
          cancel={() => setEditAnchorModal(false)}
        />
      )}
    </div>
  );
}
