import React, { useState, useEffect } from "react";
import axios from "axios";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ModalStatus from "./ModalStatus";
import {
  Button,
  Form,
  Input,
  Select,
  Table,
  Space,
  Modal,
  Row,
  Col,
  Tooltip,
  message,
  Pagination,
  Tag,
} from "antd";
import { commentListApi, commentApproveApi } from "../../../api/api";
import {
  filterParams,
  fiterIsAllParams,
  TABLE_AUDIT_STATUS,
  AUDIT_STATUS,
  SORT,
} from "../../../config/constant";
import moment from "moment";
import "./Message.scss";
import { useParams, Link } from "react-router-dom";
const { Option } = Select;
const { confirm } = Modal;
export default function MessageList() {
  const [form] = Form.useForm();
  const [userList, setUserLit] = useState([]);
  const [condition, setCondition] = useState({});
  const [curStatus, setStatus] = useState("all");
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [commentId, setCommentId] = useState();
  const [channel, setChannel] = useState();
  const [options, setOptions] = useState([
    { value: "steam", label: "steam版" },
    { value: "mobile", label: "移动版" },
  ]);
  // 不通过时理由弹窗
  const [statusModalVisible, setStatusModalVisible] = useState(false);

  const getMessageList = (params, page) => {
    let allParams = {};
    if (params && params !== {}) {
      allParams.filter = { ...params };
    }
    if (params.orderby) {
      allParams.orderby = params.orderby;
      delete allParams.filter.orderby;
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = 10;
    if (page === 1) {
      allParams.pagination.need_total = true;
    }
    axios.post(commentListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { results, total } = res.data.data;
        setUserLit(results);
        if (total) {
          setTotal(total.results);
        }
        setCurrent(page);
      } else if (res.data.err === 1018) {
        message.error("更新失败，用户留言状态有更新，请刷新页面");
      } else if (res.data.err === 1020) {
        message.error("严重错误，建议联系技术团队");
      } else {
        message.error("获取留言列表失败");
      }
    });
  };
  const initList = (page) => {
    const values = form.getFieldsValue();
    onFinish(values, page);
  };
  useEffect(() => {
    initList();
  }, []);
  const onFinish = (values, page) => {
    let params = {};
    params = filterParams(values);
    // 留言类的入参为all的均为空
    params = fiterIsAllParams(params);
    getMessageList(params, page ? page : 1);
    setCondition(params);
  };
  const auditMessageOk = (status, comment_id, channel, values) => {
    console.log(status, comment_id, channel, values, "状态");
    const params = {};
    params.token = localStorage.getItem("token");
    params.comment_id = comment_id;
    params.channel = channel;
    params.status = status;
    if (status === "denied" && values) {
      params.reason = values.reason;
    }
    axios.post(commentApproveApi, { ...params }).then((res) => {
      if (res.data && res.data.err === 0) {
        message.success("修改成功");
        // 刷新信息列表
        initList(current);
      } else if (res.data && res.data.err === 1018) {
        message.error("留言信息已更改，即将刷新页面");
        setTimeout(() => {
          initList(current);
        }, 2000);
      } else {
        message.error("修改失败");
      }
    });
  };
  const auditMessage = (status, comment_id, channel) => {
    confirm({
      title: "审核留言",
      icon: <QuestionCircleOutlined />,
      content: `确认将留言状态设置为${TABLE_AUDIT_STATUS[status]}吗？`,
      okText: "确认",
      cancelText: "取消",
      onOk() {
        auditMessageOk(status, comment_id, channel);
      },
    });
  };
  const columns = [
    {
      title: "消息ID",
      dataIndex: "comment_id",
      key: "comment_id",
      width: 100,
      render: (_, record) => (
        <Link
          to={`/messageList/detail?comment_id=${record.comment_id}&channel=${record.channel}`}
        >
          {record.comment_id}
        </Link>
      ),
    },
    {
      title: "用户昵称",
      dataIndex: "nickname",
      key: "nickname",
      width: 160,
      render: (_, record) => (
        <div>{record.nickname ? record.nickname : "-"}</div>
      ),
    },
    {
      title: "留言标题",
      dataIndex: "title",
      key: "title",
      width: 160,
      render: (_, record) => {
        const title = record.title.substr(0, 8) + "...";
        return (
          <div>
            {record.title.length > 10 ? (
              <Tooltip placement="topLeft" title={record.title}>
                <div>{title}</div>
              </Tooltip>
            ) : (
              <div>{record.title ? record.title : "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "留言内容",
      dataIndex: "content",
      key: "content",
      width: 160,
      render: (_, record) => {
        const content = record.content.substr(0, 8) + "...";
        return (
          <div>
            {record.content.length > 10 ? (
              <Tooltip
                placement="topLeft"
                overlayClassName="titleOverSty"
                title={record.content}
              >
                <div>{content}</div>
              </Tooltip>
            ) : (
              <div>{record.content ? record.content : "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "发布时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 180,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "审批时间",
      dataIndex: "approved_at",
      key: "approved_at",
      width: 180,
      render: (_, record) => (
        <div>
          {record.status === "pending"
            ? "-"
            : moment(record.approved_at).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      ),
    },

    {
      title: "审核状态",
      dataIndex: "status",
      key: "status",
      width: 110,
      render: (_, record) => (
        <>
          <Tag
            color={
              record.status === "pending"
                ? "magenta"
                : record.status === "approved"
                ? "green"
                : record.status === "denied"
                ? "red"
                : record.status === "blocked"
                ? "#333"
                : "red"
            }
          >
            {TABLE_AUDIT_STATUS[record.status]}
          </Tag>
        </>
      ),
    },
    {
      title: "操作",
      fixed: "right",
      key: "operate",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {record.status === "pending" && (
            <>
              <Button
                type="link"
                onClick={() =>
                  auditMessage("approved", record.comment_id, record.channel)
                }
              >
                通过
              </Button>
              <Button
                type="link"
                // onClick={() => auditMessage("denied", record.comment_id)}
                onClick={() => {
                  setStatusModalVisible(true);
                  setCommentId(record.comment_id);
                  setChannel(record.channel);
                }}
              >
                不通过
              </Button>
            </>
          )}
          {["approved"].includes(record.status) && (
            <>
              <Button
                type="link"
                onClick={() =>
                  auditMessage("pending", record.comment_id, record.channel)
                }
              >
                待定
              </Button>
              <Button
                type="link"
                onClick={() =>
                  auditMessage("blocked", record.comment_id, record.channel)
                }
              >
                屏蔽
              </Button>
            </>
          )}
          {record.status === "blocked" && (
            <Button
              type="link"
              onClick={() =>
                auditMessage("pending", record.comment_id, record.channel)
              }
            >待定</Button>
          )}
          {record.status === "denied" && (
            <>
              <Button
                type="link"
                onClick={() =>
                  auditMessage("pending", record.comment_id, record.channel)
                }
              >待定</Button>
              <div style={{ color: "red" }}>
                <Tooltip title={record.reason} overlayClassName="titleOverSty">
                  <span>{record.reason.length > 8 ?
                       record.reason.substr(0, 6) + "..." : record.reason}</span>
                </Tooltip>
              </div>
            </>
          )}
        </Space>
      ),
    },
  ];
  const pageChange = (value) => {
    setCurrent(value);
    getMessageList(condition, value);
  };
  const changeStatus = (e) => {
    console.log(e);

    setStatus(e);
  };
  return (
    <div>
      <Form
        form={form}
        className="form"
        onFinish={onFinish}
        initialValues={{
          status: "all",
          is_forged: "all",
          orderby: "created_at",
          device_divi: "steam",
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="device_divi"
              label="留言版面"
              rules={[
                {
                  required: true,
                  message: "请选择留言版面",
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder="请选择留言版面"
                options={options}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="留言状态"
              name="status"
              rules={[
                {
                  required: true,
                  message: "请选择留言状态",
                },
              ]}
            >
              <Select
                placeholder="请选择留言状态"
                style={{ borderRadius: ".3125rem" }}
                onChange={changeStatus}
              >
                {AUDIT_STATUS.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="留言类型"
              name="is_forged"
              rules={[
                {
                  required: true,
                  message: "请选择留言类型",
                },
              ]}
            >
              <Select
                placeholder="请选择留言类型"
                style={{ borderRadius: ".3125rem" }}
              >
                <Option value={true}>伪造留言</Option>
                <Option value={false}>真实留言</Option>
                <Option value="all">所有留言</Option>
              </Select>
            </Form.Item>
          </Col>
          {curStatus === "approved" && (
            <Col span={12}>
              <Form.Item
                label="排序规则"
                name="orderby"
                rules={[
                  {
                    required: true,
                    message: "请选择排序规则",
                  },
                ]}
              >
                <Select
                  placeholder="请选择排序规则"
                  style={{ borderRadius: ".3125rem" }}
                  datasource={SORT}
                >
                  {SORT.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name="user" label="用户昵称">
              <Input placeholder="请输入用户昵称" style={{ width: "140" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" width={140}>
              查询
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <Table
        rowKey={(record) => record.comment_id}
        columns={columns}
        dataSource={userList}
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
      {userList && userList.length > 0 && (
        <div className="pagination">
          <Pagination
            current={current}
            total={total}
            onChange={(page) => pageChange(page)}
            showSizeChanger={false}
            showTotal={() =>
              `共 ${total} 条留言 第 ${current} / ${Math.ceil(total / 10)} 页`
            }
          />
        </div>
      )}
      {statusModalVisible && (
        <ModalStatus
          visible={statusModalVisible}
          auditMessageOk={auditMessageOk}
          commentId={commentId}
          channel={channel}
          cancel={() => setStatusModalVisible(false)}
        />
      )}
    </div>
  );
}
