import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Select,
  Table,
  Space,
  Modal,
  Row,
  Col,
  message,
  Pagination,
  Tooltip,
} from "antd";
import {
  // activationCodeInvalidApi,
  appVersionListApi,
  channelApi,
} from "../../../api/api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IVFEditModal from "./Edit";
import {
  filterParams,
  APP_STATUS,
  ALL_CHANNEL,
  APP_VERSION_STATUS,
  DEVICE_TYPE_CREATE,
  distint,
} from "../../../config/constant";
import { useParams, Link } from "react-router-dom";
const { Option } = Select;
const { confirm } = Modal;

export default function AppVersionList() {
  const [form] = Form.useForm();
  const params = useParams();
  const [versionList, setVersionLit] = useState([]);
  const [condition, setCondition] = useState({});
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [editAnchorModal, setEditAnchorModal] = useState(false);
  const [anchorInfo, setAnchorInfo] = useState({});
  const [channelList, setChannelList] = useState([]);
  const [showData, setShowData] = useState({});

  const getVersionsList = (params, page) => {
    let allParams = {
      filter: {},
    };
    if (params) {
      allParams.filter = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = 10;
    axios.post(appVersionListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { appversions, pagination } = res.data.data;
        setTotal(pagination.count);
        setCurrent(pagination.page);
        setVersionLit(appversions);
      } else {
        message.error("获取app版本列表失败");
      }
    });
  };
  useEffect(() => {
    getChannelList();
    const values = form.getFieldsValue();
    onFinish(values);
  }, []);
  const onFinish = (values) => {
    let params = {};
    params = filterParams(values);
    getVersionsList(params, 1);
    setCondition(params);
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          // 渠道去重
          newArr = distint(newArr);
          setChannelList(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          console.log(newArr, newObj, "newObj");
          setShowData(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  // const isDeleteItem = (id) => {
  //   confirm({
  //     title: "删除版本记录",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "确认删除当前版本？",
  //     okText: "确定",
  //     cancelText: "取消",
  //     onOk() {
  //       deleteItem(id);
  //     },
  //   });
  // };
  const initList = (page) => {
    const values = form.getFieldsValue();
    onFinish(values, page);
  };
  // const deleteItem = (id) => {
  //   axios
  //     .post(activationCodeInvalidApi, {
  //       ...params,
  //     })
  //     .then((res) => {
  //       if (res.data && res.data.err === 0) {
  //         const values = form.getFieldsValue();
  //         if (params.id) {
  //           onFinish({ ...values, id: params.id });
  //         } else {
  //           onFinish(values);
  //         }
  //       } else {
  //         message.error("删除数据失败");
  //       }
  //     });
  // };
  const columns = [
    {
      title: "版本号",
      dataIndex: "version",
      key: "version",
      width: 100,
      render: (_, record) => (
        <Link
          to={`/appList/detail?versionId=${record.version}&channel=${record.channel}`}
        >
          {record.version}
        </Link>
      ),
    },
    {
      title: "用户渠道",
      dataIndex: "channel",
      key: "channel",
      width: 200,
      render: (_, record) => <div>{showData[record.channel]}</div>,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, record) => <div>{APP_VERSION_STATUS[record.status]}</div>,
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      width: 180,
      render: (_, record) => {
        const subDes = record.description.substr(0, 8) + "...";
        return (
          <div>
            {record.description.length > 10 ? (
              <Tooltip
                placement="topLeft"
                title={record.description}
                overlayClassName="titleOverSty"
              >
                <div>{subDes}</div>
              </Tooltip>
            ) : (
              <div>{record.description ? record.description : "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "商店链接",
      dataIndex: "url",
      key: "url",
      width: 220,
      render: (_, record) => <div>{record.url ? record.url : "-"}</div>,
    },
    {
      title: "操作",
      fixed: "right",
      key: "operate",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setAnchorInfo(record);
              setEditAnchorModal(true);
            }}
            type="link"
          >
            编辑
          </Button>
          {/* <Button type="link" danger onClick={() => isDeleteItem(record.id)}>
            删除
          </Button> */}
        </Space>
      ),
    },
  ];
  const pageChange = (value) => {
    setCurrent(value);
    getVersionsList(condition, value);
  };
  return (
    <div>
      <Form form={form} className="form" onFinish={onFinish}>
        <Row>
          <Col span={12}>
            <Form.Item label="用户渠道" name="channel">
              <Select placeholder="请选择用户渠道">
                {channelList &&
                  channelList.map((item) => (
                    <Option value={item.channel} key={item.label}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="生效状态" name="status">
              <Select placeholder="请选择生效状态">
                {APP_STATUS.map((item) => (
                  <Option value={item.value} key={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="version" label="版本号">
              <Input placeholder="请输入app版本号" style={{ width: "140" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" width={140}>
              查询
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <Table
        rowKey={(record) => record}
        columns={columns}
        dataSource={versionList}
        pagination={false}
        scroll={{
          y: 420,
        }}
      />
      {versionList && (
        <div className="pagination">
          <Pagination
            current={current}
            total={total}
            onChange={(page) => pageChange(page)}
            showSizeChanger={false}
            showTotal={(total) =>
              `共 ${total} 个版本 第 ${current} / ${Math.ceil(total / 10)} 页`
            }
          />
        </div>
      )}
      {editAnchorModal && (
        <IVFEditModal
          editAnchorModal={editAnchorModal}
          record={anchorInfo}
          initList={initList}
          cancel={() => setEditAnchorModal(false)}
        />
      )}
    </div>
  );
}
