const SYSTEM_ERROR_MSG = "系统错误，请重试";
const SYSTEM_EXCEPTION_MSG = "系统异常，请稍后重试";

const ERROR_MSG = {
  1000: { msg: "参数为空", auto: true },
  1001: { msg: "参数错误，请重新查询", auto: true },
  1010: { msg: "账号登陆过期，请重新登录", auto: true },
  1007: { msg: SYSTEM_ERROR_MSG, auto: true },
  1011: { msg: "账号密码错误,请重新输入", auto: true },
};
// 默认MSG
export const DEFAUlT_MSG = SYSTEM_EXCEPTION_MSG;
// 获取错误消息
export function getErrorMsg(code) {
  return ERROR_MSG[code] || {};
}
