import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Col,
  Row,
  message,
} from "antd";
import axios from "axios";
import {
  filterParams,
  formatTime,
  linkDownload,
  ACTIVATE_CHAPTER_EXA,
  DEVICE_ARR,
  distint,
} from "../../../config/constant";
import moment from "moment";
import { cdkGenerateApi, channelApi } from "../../../api/api";
import "./Create.scss";
import { DEVICE_TYPE_CREATE } from "../../../config/constant";
const { Option } = Select;
const { TextArea } = Input;
export default function CDKeyCreate() {
  const [form] = Form.useForm();
  const [codeList, setCodeList] = useState([]);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const onFinish = (values) => {
    let params = {};
    params = filterParams(values);
    getCodeList(params);
  };
  useEffect(() => {
    // 获取用户渠道下拉值
    getChannelList();
  }, []);
  const getCodeList = (params) => {
    setLoading(true);
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    axios
      .post(cdkGenerateApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setCodeList(res.data.data.codes);
          setUrl(res.data.data.url);
        } else {
          if (res.data.err === 1009) {
            message.error("备注信息已存在，请重新输入");
          } else {
            message("生成激活码失败");
          }
        }
      });
    setLoading(false);
  };
  const getChannelList = async () => {
    let newArr = [];
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          // 渠道去重
          newArr = distint(newArr);
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        className="form"
        initialValues={{
          chap: 0,
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="用户渠道"
              name="channel"
              rules={[
                {
                  required: true,
                  message: "请输入用户渠道",
                },
              ]}
            >
              <Select placeholder="请选择用户渠道">
                {channelList &&
                  channelList.map((item) => (
                    <Option value={item.channel} key={item.label}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="sub_id"
              label="激活章节"
              rules={[
                {
                  required: true,
                  message: "请选择激活章节",
                },
              ]}
            >
              <Select placeholder="请选择激活章节">
                {ACTIVATE_CHAPTER_EXA.map((item) => (
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="number"
              label="激活码数量"
              rules={[
                {
                  required: true,
                  message: "请输入激活码数量",
                },
              ]}
            >
              <InputNumber placeholder="请输入激活码数量" min={1} max={5000} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="remark"
              label="备注"
              rules={[
                {
                  required: true,
                  message: "请输入备注信息",
                },
              ]}
            >
              <TextArea
                maxLength={20}
                className="styText"
                placeholder="请输入备注信息"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          {/* <Col span={14}> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
              loading={loading}
            >
              创建
            </Button>
          </Form.Item>
          {/* </Col> */}
        </Row>
      </Form>
      <div className="bottom">
        <div className="code">
          {codeList.map((item) => (
            <span className="item" key={item}>
              {item + ","}
            </span>
          ))}
        </div>
        <Row className="downButton">
          {/* <div className="downButton"> */}
          <Button
            className="btn"
            disabled={codeList.length === 0}
            type="primary"
            size="large"
            style={{ width: "140" }}
            onClick={() =>
              linkDownload(
                url,
                form.getFieldValue("remark") ||
                  moment(new Date()).format("YYYY-MM-DD") + "激活码"
              )
            }
          >
            下载激活码
          </Button>
          {/* </div> */}
        </Row>
      </div>
    </div>
  );
}
