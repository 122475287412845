// 存储cookie
export const setCookie = (name, value, expiryDate) => {
  //expiryDate是限期
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + expiryDate);
  document.cookie = name + "=" + value + "; expiryDate=" + currentDate;
};

//获取cookie
export const getCookie = (name) => {
  let arr = document.cookie.split("; ");
  for (let i = 0; i < arr.length; i++) {
    let arr1 = arr[i].split("=");
    if (arr1[0] === name) {
      return arr1[1];
    }
  }
  return "";
};
// 清除cookie
export const removeCookie = (name) => {
  setCookie(name, "", 0);
};
