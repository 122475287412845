import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Col,
  Row,
  message,
} from "antd";
import axios from "axios";
import { filterParams, PALTFORMFILTER } from "../../../config/constant";
import { streamerCreateApi } from "../../../api/api";
const { Option } = Select;
export default function AnchorCreate() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let params = {};
    params = filterParams(values);
    createAnchor(params);
  };
  const createAnchor = (params) => {
    let allParams = {};
    if (params) {
      allParams = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    axios
      .post(streamerCreateApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("主播创建成功");
          form.resetFields();
        } else if (res.data.err !== 1001) {
          message.error("创建失败，直播平台和房间号已存在");
        } else {
          message.error("主播创建失败");
        }
        setLoading(false);
      });
  };
  return (
    <div>
      <Form form={form} onFinish={onFinish} className="form">
        <Row>
          <Col span={12}>
            <Form.Item
              name="nickname"
              label="主播昵称"
              rules={[
                {
                  required: true,
                  message: "请输入主播昵称",
                },
              ]}
            >
              <Input placeholder="请输入主播昵称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="直播平台"
              name="platform"
              rules={[
                {
                  required: true,
                  message: "请选择用户渠道",
                },
              ]}
            >
              <Select placeholder="请选择用户渠道" style={{ width: 175 }}>
                {PALTFORMFILTER.map((item) => (
                  <Option
                    key={item.label}
                    value={item.value}
                    disabled={item.value === "steam"}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="房间号"
              name="room"
              rules={[
                {
                  required: true,
                  message: "请输入房间号",
                },
              ]}
            >
              <Input placeholder="请输入房间号" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="url" label="直播间链接">
              <Input placeholder="请输入直播间链接" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="weight" label="权重">
              <InputNumber min={0} placeholder="请输入主播权重" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="备注">
              <Input maxLength={100} placeholder="请输入备注信息" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="styButton">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
              loading={loading}
            >
              创建
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
