import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, message } from "antd";
import axios from "axios";
import { appAppversionPublicApi, appversionPublishApi } from "../../../api/api";
const { Meta } = Card;

export default function VersionManage() {
  const [versionList, setVersionList] = useState([]);
  useEffect(function () {
    appAppversionPublic();
  }, []);
  const appAppversionPublic = () => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    axios.post(appAppversionPublicApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { appversions } = res.data.data;
        setVersionList(appversions);
      } else if (res.data.err === 1020) {
        message.error("数据库中有脏数据,请联系技术人员");
      } else {
        message.error("获取ivf版本列表失败");
      }
    });
  };
  const publish = (params) => {
    let allParams = {};
    if (params) {
      allParams.version = params.version;
      allParams.channel = params.channel;
    }
    allParams.token = localStorage.getItem("token");
    axios.post(appversionPublishApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        message.success("上线成功");
      } else {
        message.error("上线失败");
      }
      appAppversionPublic();
    });
  };
  return (
    <div>
      <Row gutter={16}>
        {versionList &&
          versionList.map((version) => {
            const { channel, current_version, newest_version, newest_desc } =
              version;
            return (
              <>
                <Col span={8} style={{ marginTop: "20px" }}>
                  <Card
                    title={channel ? channel : "暂无"}
                    extra={
                      <Button
                        type="link"
                        onClick={() =>
                          publish({ channel, version: newest_version })
                        }
                        disabled={
                          current_version && newest_version === current_version
                        }
                      >
                        一键上线
                      </Button>
                    }
                    style={{
                      width: 300,
                    }}
                  >
                    <Meta
                      title={`最新版本:${newest_version}`}
                      description={`描述:${newest_desc}`}
                    />
                    <Meta
                      style={{ marginTop: "20px" }}
                      title={`当前版本:${
                        current_version ? current_version : "暂无"
                      }`}
                    />
                  </Card>
                </Col>
              </>
            );
          })}
      </Row>
    </div>
  );
}
