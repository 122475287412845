import React, { useEffect, useState } from "react";
import qs from "qs";
import { ExclamationCircleOutlined, SwapOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  filterParams,
  DEVICE_ARR,
  distint,
  GOODS_SUPPLEMENT,
} from "../../../config/constant";
import { Form, Modal, Row, Col, message, Button, Input, Select } from "antd";
import {
  pureSupplementSubmitApi,
  userInfoApi,
  channelApi,
  goodslistApi,
} from "../../../api/api";
import { useNavigate, useLocation } from "react-router-dom";
import "./Detail.scss";
const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;

export default function NoBillSupplement() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  // const [orderInfo, setOrderInfo] = useState({});
  const [userId, setUserId] = useState();
  const [channel, setChannel] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [channelObj, setChannelObj] = useState({});
  const [goodsTypeList, setGoodsTypeList] = useState([]);
  const [goodsObj, setGoodsObj] = useState({});
  const [selectSkuType, setSelectSkuType] = useState("");
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getUserInfo = (userId, channel) => {
    axios
      .post(userInfoApi, {
        token: localStorage.getItem("token"),
        user_id: userId,
        channel,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setUserInfo(res.data.data.user);
          // setUserStatus(res.data.data.user.disable === 0);
        } else {
          message.error("获取列表失败");
        }
      });
  };
  const getGoodslist = (channel) => {
    axios
      .post(goodslistApi, {
        token: localStorage.getItem("token"),
        // user_id: userId,
        channel,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setGoodsTypeList(res.data.data.GoodsList);
          let obj = {};
          res.data.data.GoodsList.forEach((item) => {
            obj[item.value] = item.label;
          });
          console.log(obj, "res.data.data");
          setGoodsObj(obj);
        } else {
          message.error("获取商品类型列表失败");
        }
      });
  };

  const getOrderCreateApi = (values) => {
    axios
      .post(pureSupplementSubmitApi, {
        token: localStorage.getItem("token"),
        user_id: userId,
        channel,
        ...values,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("补单成功");
          setTimeout(() => {
            navigate(`/user/detail?userId=${userId}&channel=${channel}`);
          }, 100);
        } else {
          message.error("补单失败");
        }
      });
  };
  const onFinish = (values) => {
    console.log(goodsObj, selectSkuType);
    confirm({
      title: "订单补单操作",
      icon: <ExclamationCircleOutlined />,
      content: `确认补单的商品类型是'${goodsObj[selectSkuType]}'吗`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let params = {};
        params = filterParams(values);
        // 调取补单接口
        if (params.sku_id) {
          params.sku_name = goodsObj[params.sku_id];
        }
        getOrderCreateApi(params);
      },
      onCancel() {
        return;
      },
    });
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const changeSkuType = (e) => {
    console.log("输出类型", e);
    if (e) {
      setSelectSkuType(e.split("_")[0]);
    }
  };
  useEffect(() => {
    getChannelList();
    if (params.userId && params.channel) {
      getGoodslist(params.channel);
      const userId = Number(params.userId);
      setChannel(params.channel);
      // 调取当前用户详情信息接口
      getUserInfo(userId, params.channel);
      setUserId(userId);
    }
  }, []);
  return (
    <div>
      <div className="userInfo">
        <Form form={form} className="form" onFinish={onFinish}>
          {/* <Row>
            <Col span={1} />
            <Form.Item name="id" label="订单编号">
              <Col span={8}>
                <div className="right"> {userInfo.order_id}</div>
              </Col>
            </Form.Item>
          </Row> */}

          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="nick_name" label="用户昵称">
                <div className="right">{userInfo.nick_name}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="user_id" label="用户ID">
              <div className="right">{userInfo.user_id}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="channel" label="用户渠道">
              <div className="right">
                {channelObj[userInfo.channel]
                  ? channelObj[userInfo.channel]
                  : userInfo.channel}
              </div>
            </Form.Item>
          </Row>

          <Row>
            <Col span={1} />

            <Form.Item
              label="商品类型"
              name="sku_id"
              rules={[
                {
                  required: true,
                  message: "请选择商品类型",
                },
              ]}
            >
              <Select
                style={{ width: "140px" }}
                placeholder="请选择商品类型"
                onChange={changeSkuType}
              >
                {goodsTypeList &&
                  goodsTypeList.map((item) => (
                    <Option
                      value={item.value}
                      key={item.label}
                    >
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item
              name="remark"
              label="补单原因"
              rules={[
                {
                  required: true,
                  message: "请输入补单原因",
                },
              ]}
            >
              <TextArea
                maxLength={20}
                className="styText"
                placeholder="请输入补单原因"
              />
            </Form.Item>
          </Row>
          <Row style={{ marginTop: "120px" }}>
            <Col span={3} />
            <Col span={4}>
              <Form.Item>
                <Button
                  //   type="primary"
                  // htmlType="submit"
                  size="large"
                  style={{ width: "140" }}
                  onClick={() => form.resetFields()}
                >
                  取消
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: "140" }}
                >
                  提交
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} />
          </Row>
        </Form>
      </div>
    </div>
  );
}
