import Home from "../pages/views/Home/Home";
import Login from "../pages/views/Login/Login";
import Orders from "../pages/views/Order/Orders";
import OrderDetail from "../pages/views/Order/Detail";
import ActivationCode from "../pages/views/ActiveCode/Create";
import Code from "../pages/views/ActiveCode/List";
import Production from "../pages/views/Inventory/Production";
import Detail from "../pages/views/User/Detail";
import User from "../pages/views/User/List";
import NoBillSupplement from "../pages/views/User/NoBillSupplement";
import CodeDetail from "../pages/views/ActiveCode/Detail";
import { AuthComponent } from "../pages/views/CheckToken";
import Message from "../pages/views/Message/List";
import AnchorList from "../pages/views/Anchor/List";
import AnchorCreate from "../pages/views/Anchor/Create";
import MessageDetail from "../pages/views/Message/Detail";
import MessageCreate from "../pages/views/Message/Create";
import AnchorDetail from "../pages/views/Anchor/Detail";
import AppVersionList from "../pages/views/AppVersion/List";
import AppVersionDetail from "../pages/views/AppVersion/Detail";
import AppVersionCreate from "../pages/views/AppVersion/Create";
import IVFVersionList from "../pages/views/IvfVersion/List";
import IVFVersionDetail from "../pages/views/IvfVersion/Detail";
import IVFVersionCreate from "../pages/views/IvfVersion/Create";
import VersionManage from "../pages/views/VersionManage/List";
import NoBillSupplementList from "../pages/views/NoBillSupplement/List";
import CDKeyList from "../pages/views/ActiveCode/List";
import CDKeyDetail from "../pages/views/ActiveCode/Detail";
import CDKeyCreate from "../pages/views/ActiveCode/Create";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

const BaseRouter = () => (
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <AuthComponent>
            <Home />
          </AuthComponent>
        }
      >
        <Route index element={<User />} />
        <Route path="user" element={<User />} />
        <Route path="user/detail" element={<Detail />} />
        <Route path="user/supplement" element={<NoBillSupplement />} />
        <Route path="order" element={<Orders />} />
        <Route path="/order/detail" element={<OrderDetail />} />
        <Route path="supplement" element={<NoBillSupplementList />} />
        <Route path="create" element={<ActivationCode />} />
        <Route path="list/detail" element={<CodeDetail />} />
        <Route path="list" element={<Code />} />
        <Route path="production" element={<Production />} exact />
        <Route path="ivfList" element={<IVFVersionList />} />
        <Route path="ivfList" element={<IVFVersionList />} />
        <Route path="ivfList/detail" element={<IVFVersionDetail />} />
        <Route path="ivfCreate" element={<IVFVersionCreate />} />
        <Route path="appList" element={<AppVersionList />} />
        <Route path="appCreate" element={<AppVersionCreate />} />
        <Route path="appList/detail" element={<AppVersionDetail />} />
        <Route path="versionList" element={<VersionManage />} />
        <Route path="messageList" element={<Message />} />
        <Route path="messageCreate" element={<MessageCreate />} />
        <Route path="messageList/detail" element={<MessageDetail />} />
        <Route path="cdkeyDetail" element={<CDKeyDetail />} />
        <Route path="cdkeyList" element={<CDKeyList />} />
        <Route path="cdkeyCreate" element={<CDKeyCreate />} />
        {/* <Route path="anchorList" element={<AnchorList />} /> */}
        {/* <Route path="anchorList/detail" element={<AnchorDetail />} /> */}
        {/* <Route path="anchorCreate" element={<AnchorCreate />} /> */}
        <Route
          path="*"
          element={
            <main>
              <p>无效路径</p>
            </main>
          }
        />

        {/* <Route path="*" element={<Navigate to="/user" />} /> */}
      </Route>
      <Route path="/login" element={<Login />}></Route>
    </Routes>
  </Router>
);

export default BaseRouter;
