import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Col, Row, message } from "antd";
import axios from "axios";
import { filterParams, PLATTABLE } from "../../../config/constant";
import { commentForgeApi, streamerListApi } from "../../../api/api";
import "./Message.scss";
const { TextArea } = Input;
export default function MessageCreate() {
  const [options, setOptions] = useState([
    { value: "steam", label: "steam版" },
    { value: "mobile", label: "移动版" },
  ]);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let params = {};
    params = filterParams(values);
    createComment(params);
  };
  const createComment = (params) => {
    let allParams = {};
    if (params) {
      allParams = { ...params };
    }
    allParams.token = localStorage.getItem("token");
    axios
      .post(commentForgeApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("创建成功");
        } else if (res.data.err !== 1001) {
          message.error("创建失败");
        }
        form.resetFields();
        setLoading(false);
      });
  };
  return (
    <div>
      <Form form={form} onFinish={onFinish} className="formSty">
        <Row>
          <Col span={12}>
            <Form.Item
              name="nickname"
              label="用户昵称"
              rules={[
                {
                  required: true,
                  message: "请输入用户昵称",
                },
              ]}
            >
              <Input placeholder="请输入用户昵称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="device_divi"
              label="留言版面"
              rules={[
                {
                  required: true,
                  message: "请选择留言版面",
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder="请选择留言版面"
                options={options}
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="streamer_id" label="转发主播">
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                mode="multiple"
                placeholder="请选择需要转发的主播"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row>
          <Col span={21}>
            <Form.Item
              name="title"
              label="留言标题"
              rules={[
                {
                  required: true,
                  message: "请输入留言标题,标题上限25个字",
                },
              ]}
            >
              <Input
                placeholder="请输入留言标题,标题上限25个字"
                maxLength={25}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={21}>
            <Form.Item
              name="content"
              label="消息内容"
              rules={[
                {
                  required: true,
                  message: "请输入消息内容,上限输入1000字，提交后会直接通过生效，请反复核查留言内容",
                },
              ]}
            >
              <TextArea
                showCount
                style={{ height: "300px", whiteSpace: "pre-wrap" }}
                maxLength={1000}
                placeholder="请输入消息内容,上限输入1000字，提交后会直接通过生效，请反复核查留言内容"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
              loading={loading}
            >
              创建
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
