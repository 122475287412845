import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Divider,
  Space,
} from "antd";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

import {
  filterParams,
  DEVICE_TYPE_CREATE,
  distint,
  ALL_CHANNEL,
} from "../../../config/constant";
import "./Create.scss";
import {
  appIvfCreateApi,
  channelApi,
  appIvfListApi,
  appversionListVersionOnlyApi,
} from "../../../api/api";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
export default function IVFVersionCreate() {
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [newSelectobj, setNewSelectobj] = useState({});
  const [previous, setPrevious] = useState({});
  const [previousBtn, setPreviousBtn] = useState(false);
  const onFinish = (values) => {
    setLoading(true);

    // let params = {};
    // params = filterParams(values);
    createIvf(values);
  };
  useEffect(() => {
    getChannelList();
    getSelectList();
  }, []);
  const getSelectList = async () => {
    await axios
      .post(appversionListVersionOnlyApi, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setNewSelectobj(res.data.data.appversions);
        } else {
          message.error("获取app版本信息失败");
        }
      });
  };
  const getChannelList = async () => {
    let newArr = [];
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, {
        token: localStorage.getItem("token"),
        category: "sku",
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          newArr = distint(newArr);
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const createIvf = (params) => {
    let allParams = {};
    if (params) {
      allParams.edition_id = Number(params.edition_id);
      allParams.description = params.description;
      allParams.app_versions = params.app_versions;
      Object.keys(allParams.app_versions).forEach((item) => {
        if (!allParams.app_versions[item]) {
          allParams.app_versions[item] = "";
        }
      });
    }
    allParams.token = localStorage.getItem("token");
    axios
      .post(appIvfCreateApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("ivf版本创建成功");
          form.resetFields();
        } else if (res.data.err === 1009) {
          message.error("ivf版本号已存在");
        } else {
          message.error("ivf版本创建失败");
        }
        setLoading(false);
      });
  };
  const getPrevious = async () => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = 1;
    allParams.pagination.limit = 1;
    await axios.post(appIvfListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { ivfs } = res.data.data;
        const { url, app_versions, description, edition_id } = ivfs[0];
        form.setFieldValue("url", url);
        form.setFieldValue("app_versions", app_versions);
        form.setFieldValue("edition_id", edition_id);
        form.setFieldValue("description", description);
        setPrevious(ivfs[0]);
        setPreviousBtn(true);
      } else {
        message.error("获取ivf版本列表失败");
      }
    });
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e, channel) => {
    const obj = cloneDeep(newSelectobj);
    let newName = /^[\.\d]*$/;
    e.preventDefault();
    if (!name) {
      message.error("输入框不可为空");
      return;
    }
    if (newName.test(name)) {
      if (obj[channel]) {
        obj[channel] = [...obj[channel], name];
      } else {
        obj[channel] = [name];
      }
      setNewSelectobj(obj);
    } else {
      message.error("输入的字符只能包含数字和小数点");
      return;
    }
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const validator = (rule, value, callback) => {
    let reg = /^(0?\-?[1-9]\d*)$/;
    if (value && !reg.test(value)) {
      callback("ivf版本号需为正整数");
    } else {
      callback();
    }
  };
  return (
    <div>
      <Form form={form} onFinish={onFinish} className="form">
        <Row>
          <Col span={12}>
            <Form.Item
              name="edition_id"
              label="版本号"
              rules={[
                {
                  required: true,
                  message: "请输入整数ivf版本号",
                },
                { validator: validator },
              ]}
            >
              <InputNumber
                min={0}
                max={9999}
                placeholder="请输入整数ivf版本号"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="描述"
              rules={[
                {
                  required: true,
                  message: "请填写描述内容",
                },
              ]}
            >
              <Input maxLength={100} placeholder="请输入描述信息" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {channelList.map((item, index) => {
            return (
              <Col span={12} key={item.channel}>
                <Form.Item
                  name={["app_versions", `${item.channel}`]}
                  label={`${
                    ALL_CHANNEL[item.channel]
                      ? ALL_CHANNEL[item.channel]
                      : item.channel
                  }`}
                >
                  <Select
                    className="selectClear"
                    allowClear
                    placeholder={`请选择ace项目中兼容${item.label}最低版本号`}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.key ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: "8px 0",
                          }}
                        />
                        <Space
                          style={{
                            padding: "0 8px 4px",
                          }}
                        >
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={(e) => addItem(e, item.channel)}
                          >
                            未来版本
                          </Button>
                        </Space>
                      </>
                    )}
                    options={
                      newSelectobj[item.channel] &&
                      newSelectobj[item.channel].map((item) => ({
                        label: item,
                        value: item,
                      }))
                    }
                  ></Select>
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button
              onClick={getPrevious}
              type="primary"
              size="large"
              style={{ width: "140" }}
              disabled={previousBtn}
            >
              最新版本信息
            </Button>
          </Form.Item>
        </Row>
        <Row className="styButton">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
              loading={loading}
            >
              创建
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
