import React, { useEffect, useState } from "react";
import qs from "qs";
import {
  ExclamationCircleOutlined,
  SwapOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { filterParams, DEVICE_ARR, distint } from "../../../config/constant";
import {
  Form,
  Modal,
  Row,
  Col,
  message,
  Button,
  InputNumber,
  Input,
} from "antd";
import { supplementApi, orderGetApi, channelApi } from "../../../api/api";
import { useNavigate, useLocation } from "react-router-dom";
import "./Detail.scss";
const { confirm } = Modal;
const { TextArea } = Input;
export default function OrderDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [orderInfo, setOrderInfo] = useState({});
  const [orderId, setOrderId] = useState();
  const [channel, setChannel] = useState();
  const [channelObj, setChannelObj] = useState({});
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getOrderDetail = (orderId, channel) => {
    axios
      .post(orderGetApi, {
        token: localStorage.getItem("token"),
        order_id: orderId,
        channel,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setOrderInfo(res.data.data);
        } else {
          message.error("获取订单详情失败");
        }
      });
  };
  const supplementOrder = (values) => {
    axios
      .post(supplementApi, {
        token: localStorage.getItem("token"),
        order_id: orderId,
        channel,
        ...values,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("补单成功");
          setTimeout(() => {
            navigate("/order");
          }, 100);
        } else {
          message.error("补单操作失败");
        }
      });
  };
  const onFinish = (values) => {
    confirm({
      title: "订单补单操作",
      icon: <ExclamationCircleOutlined />,
      content: `确认补单订单为${orderInfo.order_id}，类型是'${orderInfo.sku_name}'么？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let params = {};
        params = filterParams(values);
        // 调取补单接口
        supplementOrder(params);
      },
      onCancel() {
        return;
      },
    });
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  useEffect(() => {
    getChannelList();
    if (params.orderId && params.channel) {
      setChannel(params.channel);
      // 调取当前用户详情信息接口
      getOrderDetail(params.orderId, params.channel);
      setOrderId(params.orderId);
    }
  }, []);
  return (
    <div>
      <div className="userInfo">
        <Form form={form} className="form" onFinish={onFinish}>
          <Row>
            <Col span={1} />
            <Form.Item name="orderId" label="订单编号">
              <div className="right"> {orderInfo.order_id}</div>
            </Form.Item>
          </Row>

          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="nick_name" label="用户昵称">
                <div className="right">{orderInfo.nickname}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="user_id" label="用户ID">
              <div className="right">{orderInfo.user_id}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="channel" label="用户渠道">
              <div className="right">{orderInfo.channel_name}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="sku" label="商品名">
              <div className="right">{orderInfo.sku_name}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item
              name="remark"
              label="补单原因"
              rules={[
                {
                  required: true,
                  message: "请输入补单原因",
                },
              ]}
            >
              <TextArea
                maxLength={20}
                className="styText"
                placeholder="请输入补单原因"
              />
            </Form.Item>
          </Row>
          <Row style={{ marginTop: "120px" }}>
            <Col span={3} />
            <Col span={4}>
              <Form.Item>
                <Button
                  //   type="primary"
                  // htmlType="submit"
                  size="large"
                  style={{ width: "140" }}
                  onClick={() => form.resetFields()}
                >
                  取消
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: "140" }}
                >
                  提交
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} />
          </Row>
        </Form>
      </div>
    </div>
  );
}
