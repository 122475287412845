import React, { useState } from "react";

import { Input, Modal, Form, Row, Col, Button } from "antd";
const { TextArea } = Input;
export default function ModalStatus(props) {
  const [form] = Form.useForm();
  const finish = (values) => {
    const { commentId, channel } = props;
    props.auditMessageOk("denied", commentId, channel, values);
    props.cancel();
  };
  return (
    <Modal
      title="不通过原因"
      footer={null}
      // centered
      open={props.visible}
      onCancel={props.cancel}
      forceRender={true}
    >
      <Form form={form} className="form" onFinish={finish}>
        <Row>
          <div style={{ marginLeft: "-40px" }}></div>

          <Form.Item
            name="reason"
            label="原因"
            rules={[
              {
                required: true,
                message: "请输入不通过原因",
              },
            ]}
          >
            <TextArea
              style={{
                height: 120,
              }}
            />
          </Form.Item>
        </Row>
        <div className="bottomBtn">
          <Button
            onClick={() => props.cancel()}
            size="large"
            style={{ width: "140" }}
          >
            取消
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "140" }}
          >
            确认
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
