import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  message,
  Modal,
  Row,
  Col,
  Space,
  Pagination,
} from "antd";
import qs from "qs";
import axios from "axios";
import {
  CODE_USER_STATUS,
  formatTime,
  filterParams,
  DEVICE_TABLE,
} from "../../../config/constant";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  cdkDetailApi,
  cdkBanApi,
} from "../../../api/api";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";

const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CDKeyDetail() {
  const [form] = Form.useForm();
  const location = useLocation();
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const [list, setList] = useState();
  const [total, setTotal] = useState(0);
  const [condition, setCondition] = useState();
  const [current, setCurrent] = useState(1);
  const [pagination, setPagination] = useState({ limit: 10, page: 1 });
  const [limit, setLimit] = useState(10);
  const onFinish = (values) => {
    let data = {};
    data = filterParams(values);
    if (values.codes) {
      delete data.codes;
      data.codes = values.codes.replace(/\r|\n/gi, "").replace(/\s*/g, "");
      if (data.codes.indexOf(",") > -1) {
        data.codes = data.codes.split(",");
      } else if (data.codes.indexOf("，") > -1) {
        data.codes = data.codes.split("，");
      } else {
        data.codes = [data.codes];
      }
      data.codes.forEach((item, index) => {
        if (!item) {
          data.codes.splice(index, 1);
        }
      });
    }
    setCondition({ ...data, id: Number(params.id) });
    getCodeDetailList({ ...data, id: Number(params.id) }, 1);
    setCurrent(1);
  };
  useEffect(() => {
    const values = form.getFieldsValue();
    if (params.id) {
      onFinish({ ...values, id: params.id });
    } else {
      onFinish(values);
    }
  }, []);
  const pageChange = (page) => {
    setCurrent(page);
    getCodeDetailList(condition, page);
  };
  const getCodeDetailList = (params, page) => {
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = limit;
    axios
      .post(cdkDetailApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          // const { pagination, activation_code_infos, err_codes } =
          //   res.data.data;
          // let des = "";
          // if (err_codes) {
          //   err_codes.forEach((item) => {
          //     des += item + ",";
          //   });
          //   Modal.error({
          //     title: "Error",
          //     content:
          //       err_codes.length > 10
          //         ? `含有${err_codes.length}个错误激活码`
          //         : `激活码${des}不存在`,
          //   });
          // }
          const { codes, pagination } = res.data.data;
          setList(codes);
          setTotal(pagination.total);
        }
      });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current >= moment().endOf("day");
  };
  const isDisabledCode = (record) => {
    confirm({
      title: "激活码禁用",
      icon: <QuestionCircleOutlined />,
      content: `确认将激活码${record.code}设置为禁用吗？激活码禁用后将不能使用`,
      okText: "确认",
      cancelText: "取消",
      onOk() {
        disabledCode({
          ids: [record.id],
          token: localStorage.getItem("token"),
        });
      },
    });
  };
  // 禁用激活码
  const disabledCode = (params) => {
    axios
      .post(cdkBanApi, {
        ...params,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const values = form.getFieldsValue();
          if (params.id) {
            onFinish({ ...values, id: params.id });
          } else {
            onFinish(values);
          }
        } else {
          message.error("禁用激活码失败");
        }
      });

    // 调取激活码禁用接口成功后调取列表接口
  };
  const columns = [
    {
      title: "激活码",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "用户ID",
      dataIndex: "user_id",
      key: "user_id",
      width: 150,
      render: (_, record) =>
        record.user_id !== 0 ? (
          <Link to={`/user/detail?userId=${record.user_id}`}>
            {record.user_id}
          </Link>
        ) : (
          <div>{record.user_id}</div>
        ),
    },
    {
      title: "用户名称",
      dataIndex: "nick_name",
      key: "nick_name",
      width: 200,
      render: (_, record) => (
        <div>{record.nick_name ? record.nick_name : "-"}</div>
      ),
    },
    {
      title: "使用状态",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (_, record) => (
        <div>
          {record.status === 1
            ? "禁用"
            : record.user_id > 0
            ? "已用"
            : record.status === 0
            ? "可用"
            : ""}
        </div>
      ),
    },
    {
      title: "使用时间",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 200,
      render: (_, record) => (
        <div>{moment(record.updated_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "操作",
      key: "operate",
      fixed: "right",
      width: 160,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => isDisabledCode(record)}
            disabled={record.status === 1}
          >
            禁用
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="top">
        <Form
          form={form}
          className="form"
          onFinish={onFinish}
          initialValues={{
            status: 3,
          }}
        >
          <Row>
            <Col span={10}>
              <Form.Item name="codes" label="激活码">
                <TextArea
                  rows={9}
                  style={{ width: "250px" }}
                  placeholder="请输入激活码，可批量输入，并用“,”隔开"
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Row>
                <Form.Item label="使用时间" name="time">
                  <RangePicker disabledDate={disabledDate} locale={locale} />
                </Form.Item>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item name="user_id" label="用户ID">
                    <Input placeholder="请输入用户ID" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item name="nick_name" label="用户名称">
                    <Input placeholder="请输入用户名称" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <Form.Item
                    label="使用状态"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "请选择激活码使用状态",
                      },
                    ]}
                  >
                    <Select
                      placeholder="请选择激活码使用状态"
                      style={{ width: 175 }}
                    >
                      {CODE_USER_STATUS?.map((item) => (
                        <Option key={item.label} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <div className="styButton"> */}
          <Row className="styButton">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "140" }}
              >
                查询
              </Button>
            </Form.Item>
          </Row>
          {/* </div> */}
        </Form>
      </div>
      <div>
        <Table
          rowKey={(record) => record.code}
          columns={columns}
          dataSource={list}
          pagination={false}
          scroll={{
            y: 320,
          }}
        />
        {list && (
          <div className="pagination">
            <Pagination
              current={current}
              // showTotal={(total) =>
              //   `共 ${total} 个项目 第 ${current} / ${pages} 页`
              // }
              total={total}
              onChange={(page) => pageChange(page)}
              showSizeChanger={false}
              showTotal={(total) =>
                `共 ${total} 个激活码 第 ${current} / ${Math.ceil(
                  total / 10
                )} 页`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
