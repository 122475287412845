import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Table,
  Space,
  Modal,
  Row,
  Col,
  message,
  Pagination,
  Spin,
} from "antd";
import moment from "moment";
import { userListApi, channelApi } from "../../../api/api";
import {
  USER_STATUS,
  DEVICE_TYPE,
  filterParams,
  formatTime,
  USER_INFO_STATUS,
  USER_INFO_DEVICE,
  DEVICE_TABLE,
  DEVICE_ARR,
  distint,
} from "../../../config/constant";
import { useParams, Link } from "react-router-dom";
import "./List.scss";
const { Option } = Select;
export default function UserForm() {
  const [form] = Form.useForm();
  const [query, setQuery] = useState("react");
  const params = useParams();
  const [userList, setUserLit] = useState([]);
  const [condition, setCondition] = useState({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [channelList, setChannelList] = useState([]);
  const [channelObj, setChannelObj] = useState({});
  const getUserList = (params, page) => {
    setLoading(true);
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = 10;
    axios.post(userListApi, { ...allParams }).then((res) => {
      if (res.data && res.data.err === 0) {
        const { users, pagination } = res.data.data;

        setTotal(pagination.total);
        setCurrent(pagination.page);
        setUserLit(users);
      } else {
        message.error("获取用户列表失败");
      }
    });
    setLoading(false);
  };
  useEffect(() => {
    const values = form.getFieldsValue();
    getChannelList();
    onFinish(values);
  }, []);
  const onFinish = (values) => {
    let params = {};
    params = filterParams(values);
    if (params.user_id) {
      params.user_id = Number(params.user_id);
    }
    getUserList(params, 1);
    setCondition(params);
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const columns = [
    {
      title: "用户ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (_, record) => (
        <Link
          to={`/user/detail?userId=${record.user_id}&channel=${record.channel}`}
        >
          {record.user_id}
        </Link>
      ),
    },
    {
      title: "用户名称",
      dataIndex: "nick_name",
      key: "nick_name",
    },
    {
      title: "用户渠道",
      dataIndex: "channel",
      key: "channel",
      render: (_, record) => <div>{channelObj[record.channel]}</div>,
    },
    {
      title: "注册时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 180,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "用户状态",
      dataIndex: "disable",
      key: "disable",
      render: (_, record) => <div>{USER_INFO_STATUS[record.disable]}</div>,
    },
    {
      title: "操作",
      fixed: "right",
      key: "operate",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={`/user/detail?userId=${record.user_id}&channel=${record.channel}`}
          >
            库存详情
          </Link>
          <Link
            to={`/user/supplement?userId=${record.user_id}&channel=${record.channel}`}
          >
            无单补单
          </Link>
        </Space>
      ),
    },
  ];
  const pageChange = (value) => {
    setCurrent(value);
    getUserList(condition, value);
  };
  return (
    <div>
      <Spin spinning={loading}>
        <Form
          form={form}
          className="form"
          onFinish={onFinish}
          initialValues={{
            disable: 0,
            channel: "wechat",
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="用户渠道"
                name="channel"
                rules={[
                  {
                    required: true,
                    message: "请输入用户渠道",
                  },
                ]}
              >
                <Select placeholder="请选择用户渠道">
                  {channelList &&
                    channelList.map((item) => (
                      <Option value={item.channel} key={item.label}>
                        {item.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="用户状态"
                name="disable"
                rules={[
                  {
                    required: true,
                    message: "请输入用户状态",
                  },
                ]}
              >
                <Select
                  placeholder="请输入用户状态"
                  style={{ borderRadius: "5px" }}
                >
                  {USER_STATUS.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="user_id" label="用户数字ID">
                <Input placeholder="请输入用户ID：101293" style={{ width: "140" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="alt_id" label="用户ID">
                <Input placeholder="请输入用户ID：3C8RTZ" style={{ width: "140" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nick_name" label="用户名称">
                <Input placeholder="请输入用户名称：路人甲" style={{ width: "140" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="styButton">
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" width={140}>
                查询
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Table
          rowKey={(record) => record.user_id}
          columns={columns}
          dataSource={userList}
          pagination={false}
          scroll={{
            y: 320,
          }}
        />
        {userList && (
          <div className="pagination">
            <Pagination
              current={current}
              total={total}
              onChange={(page) => pageChange(page)}
              showSizeChanger={false}
              showTotal={(total) =>
                `共 ${total} 位用户 第 ${current} / ${Math.ceil(total / 10)} 页`
              }
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
