import {
  ContainerOutlined,
  UserOutlined,
  KeyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  PlusSquareOutlined,
  MessageOutlined,
  TeamOutlined,
  MobileOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Dropdown, message, Avatar } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { layoutApi } from "../../../api/api";
import Bread from "../../components/Bread";
import "./Home.scss";
const { Header, Content, Sider } = Layout;
const rootSubmenuKeys = ["code", "message", "anchor", "ivf", "app"];
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("用户管理", "user", <TeamOutlined />),
  getItem("订单管理", "order", <ContainerOutlined />),
  getItem("收支详情", "production", <HomeOutlined />),
  // getItem("无单补单管理", "supplement", <FolderAddOutlined />),
  getItem("激活码管理", "code", <KeyOutlined />, [
    getItem("激活码列表", "cdkeyList", <UnorderedListOutlined />),
    getItem("详情列表", "cdkeyDetail", <MenuFoldOutlined />),
    getItem("创建激活码", "cdkeyCreate", <PlusSquareOutlined />),
  ]),
  getItem("留言管理", "message", <MessageOutlined />, [
    getItem("留言列表", "messageList", <UnorderedListOutlined />),
    getItem("创建留言", "messageCreate", <PlusSquareOutlined />),
  ]),
  // getItem("主播管理", "anchor", <UserOutlined />, [
  //   getItem("主播列表", "anchorList", <UnorderedListOutlined />),
  //   getItem("创建主播", "anchorCreate", <PlusSquareOutlined />),
  // ]),
  getItem("app版本生效列表", "versionList", <ContainerOutlined />),
  getItem("IVF版本管理", "ivf", <UserOutlined />, [
    getItem("版本列表", "ivfList", <MenuFoldOutlined />),
    getItem("创建版本", "ivfCreate", <PlusSquareOutlined />),
  ]),
  getItem("APP版本管理", "app", <UserOutlined />, [
    getItem("版本列表", "appList", <MobileOutlined />),
    getItem("创建版本", "appCreate", <PlusSquareOutlined />),
  ]),
];

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [openKeys, setOpenKeys] = useState([
    "code",
    // "message",
    // "anchor",
    // "ivf",
    // "app",
  ]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const layout = () => {
    axios
      .post(layoutApi, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          localStorage.clear();
          message.success("退出成功，即将返回登录页");
          setTimeout(() => {
            navigate("/login");
          }, 100);
        }
      });
  };
  useEffect(() => {
    let path = location.pathname.split("/")[1];
    if (path === "") {
      setSelectedKey("user");
    } else {
      setSelectedKey(path);
    }
  }, [location.pathname]);
  const click = (e) => {
    if (localStorage.getItem("token")) {
      setSelectedKey(e.key);
      navigate("/" + e.key);
    } else {
      setTimeout(() => {
        message.error("用户信息失效，请重新登录");
        navigate("/login");
      }, 1000);
    }
  };
  const menu = (
    <Menu className="layout">
      <Menu.Item key="/layout" onClick={layout} style={{ color: "#E41D26" }}>
        退出登录
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Layout style={{ minHeight: "100vh", padding: 0, minWidth: 1180 }}>
        <div
          style={{
            background: " #001529",
            // overflowY: "scroll",
          }}
        >
          <Sider
            theme="dark"
            mode="inline"
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <div className="logo">运营平台</div>
            <Menu
              theme="dark"
              onClick={click}
              defaultOpenKeys={["code"]}
              mode="inline"
              selectedKeys={[selectedKey]}
              items={items}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
            />
          </Sider>
        </div>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
            }}
          >
            <div className="header-left">
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </div>
              <div>
                <Bread style={{ margin: "16px 0" }} />
              </div>
            </div>
            <div className="header-right">
              <Dropdown overlay={menu}>
                <div className="avatar">
                  <div className="logo2">
                    <Avatar size="large" icon={<UserOutlined />} />
                  </div>
                </div>
              </Dropdown>
              <div className="user">{localStorage.getItem("account")}</div>
            </div>
          </Header>
          <Content
            style={{
              margin: "20px",
              padding: "20px",
              background: "#fff",
              overflow: "auto",
              minWidth: "860px",
              borderRadius: "5px",
              position: "relative",
              paddingTop: "20px",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Home;
