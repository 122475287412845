import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";
import "./scss/Bread.scss";

export default function Bread() {
  const breadcrumbNameMap = {
    "/user": "用户管理",
    "/production": "库存管理",
    "/order": "订单管理",
    "/supplement": "无单补单",
    "/order/detail": "补单",
    "/code": "激活码管理",
    "/code/list": "激活码列表",
    "/code/create": "创建激活码",
    "/cdkeyList": "激活码列表",
    "/cdkeyDetail": "激活码详情",
    "/cdkeyCreate": "创建激活码",
    "/user/detail": "用户详情",
    "/user/supplement": "无单补单",
    "/list/detail": "激活码详情",
    "/message": "留言管理",
    "/messageList": "留言列表",
    "/messageCreate": "创建留言",
    "/messageList/detail": "留言详情",
    "/anchor": "主播管理",
    "/anchorList": "主播列表",
    "/anchorCreate": "主播创建",
    "/anchorList/detail": "主播详情",
    "/ivfList": "ivf版本列表",
    "/ivfCreate": "创建ivf版本",
    "/ivfList/detail": "版本详情",
    "/appList": "app版本列表",
    "/appCreate": "创建app版本",
    "/appList/detail": "版本详情",
    "/versionList": "app版本生效列表",
  };
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      {/* <Link to="/">主页</Link> */}
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return <Breadcrumb className="bread">{breadcrumbItems}</Breadcrumb>;
}
