import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  filterParams,
  APP_STATUS,
  DEVICE_TYPE_CREATE,
  ALL_CHANNEL,
} from "../../../config/constant";
import { Form, Row, Col, message, Button, Input, Select, Divider } from "antd";
import WrappedModal from "../../components/WrappedModal/Modal";
import { appVersionUpdateApi, channelApi } from "../../../api/api";
const { Option } = Select;
export default function IVFEditModal(props) {
  const { version, url, description, status, id, channel } = props.record;
  const [form] = Form.useForm();
  const [channelList, setChannelList] = useState([]);

  useEffect(() => {
    getChannelList();
  }, []);
  const getChannelList = async () => {
    const newArr = [];

    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_TYPE_CREATE.forEach((item) => {
            newArr.push(...res.data.data.channel[item.value]);
          });
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const onFinish = (values) => {
    let data = {};
    data = filterParams(values);
    data.token = localStorage.getItem("token");
    data.id = id;
    axios.post(appVersionUpdateApi, { ...data }).then((res) => {
      if (res.data && res.data.err === 0) {
        message.success("修改版本信息成功");
        // 刷新列表
        props.initList();
      } else {
        message.error("修改版本信息失败");
      }
      props.cancel();
    });
  };
  return (
    <WrappedModal
      width={700}
      centered
      title="编辑版本信息"
      open={props.editAnchorModal}
      onCancel={props.cancel}
      forceRender={true}
      footer={null}
    >
      <>
        <Form
          onFinish={onFinish}
          form={form}
          className="form"
          initialValues={{
            channel,
            version,
            description,
            url,
            status,
          }}
        >
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item
                name="version"
                label="版本号"
                rules={[
                  {
                    required: true,
                    message: "请填写版本号",
                  },
                ]}
              >
                {version}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item
                label="用户渠道"
                name="channel"
                rules={[
                  {
                    required: true,
                    message: "请输入用户渠道",
                  },
                ]}
              >
                {ALL_CHANNEL[channel]}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item
                label="版本状态"
                name="status"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  {APP_STATUS.map((item) => (
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item name="url" label="商店链接">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2} />
            <Col span={12}>
              <Form.Item name="description" label="描述">
                <Input placeholder="请输入描述信息" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="bottomBtn">
            <Button
              onClick={() => props.cancel()}
              size="large"
              style={{ width: "140" }}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: "140" }}
            >
              修改
            </Button>
          </div>
        </Form>
      </>
    </WrappedModal>
  );
}
