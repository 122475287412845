import React, { useEffect, useState } from "react";
import qs from "qs";
import axios from "axios";
import { TABLE_AUDIT_STATUS, EMOTION } from "../../../config/constant";
import { QuestionCircleOutlined, LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { Form, Modal, Row, Col, message, Button, Avatar } from "antd";
import ModalStatus from "./ModalStatus";
import { commentGetApi, commentApproveApi } from "../../../api/api";
import { useLocation } from "react-router-dom";
import ReactModal from "./ReactionModal";
import "./Detail.scss";
const { confirm } = Modal;
export default function MessageDetail() {
  const location = useLocation();
  const [messageInfo, setMessageInfo] = useState({});
  const [emojiInfo, setEmojiInfo] = useState({});
  const [newEmojis, setNewEmojis] = useState([]);
  const [channel, setChannel] = useState();
  const [reactionModalVisival, setReactionModalVisival] = useState(false);
  // 不通过时理由弹窗
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [commentId, setCommentId] = useState();

  const params = qs.parse(location.search.replace(/^\?/, ""));
  console.log(params, "params");
  const getMessageInfo = (comment_id, channel) => {
    let allParams = {};
    allParams.token = localStorage.getItem("token");
    allParams.comment_id = comment_id;
    allParams.channel = channel;
    axios
      .post(commentGetApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const { comment } = res.data.data;
          setMessageInfo(comment);
          const newArr = [
            {
              emoji_id: 1,
              value: {},
            },
            {
              emoji_id: 4,
              value: {},
            },
            {
              emoji_id: 3,
              value: {},
            },
            {
              emoji_id: 2,
              value: {},
            },
          ];

          newArr.forEach((item) => {
            const realInfo = comment.emojis.find(
              (i) => i.emoji_id === item.emoji_id
            );
            const fakerInfo = comment.forged_emojis.find(
              (i) => i.emoji_id === item.emoji_id
            );
            console.log(realInfo, fakerInfo);
            item.value.realCount = realInfo.amount - fakerInfo.amount;
            item.value.fakeCount = fakerInfo.amount;
          });
          setNewEmojis(newArr);
        } else if (res.data.err === 1020) {
          message.error("严重错误，建议联系技术团队");
        } else {
          message.error("获取留言详情失败");
        }
      });
  };
  const initInfoData = () => {
    if (params.comment_id && params.channel) {
      const comment_id = Number(params.comment_id);

      // 调取当前用户详情信息接口
      getMessageInfo(comment_id, params.channel);
    }
  };
  const auditMessageOk = (status, comment_id, channel, values) => {
    const params = {};
    params.token = localStorage.getItem("token");
    params.comment_id = comment_id;
    params.channel = channel;
    params.status = status;
    if (status === "denied" && values) {
      params.reason = values.reason;
    }
    axios
      .post(commentApproveApi, {
        ...params,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("修改成功");
          // 刷新详情列表
          initInfoData();
        } else {
          message.error("修改失败");
        }
      });
  };
  const auditMessage = (status, comment_id, channel) => {
    confirm({
      title: "审核留言",
      icon: <QuestionCircleOutlined />,
      content: `确认将留言状态设置为${TABLE_AUDIT_STATUS[status]}吗？`,
      okText: "确认",
      cancelText: "取消",
      onOk() {
        auditMessageOk(status, comment_id, channel);
      },
    });
  };
  useEffect(() => {
    initInfoData();
  }, []);

  return (
    <div>
      <div className="userInfo">
        {params.streamer_id && (
          <div onClick={() => window.history.back()} className="return">
            <span>
              <LeftOutlined />
            </span>
            <span>返回主播详情</span>
          </div>
        )}
        <div className="topTitle">留言详情</div>
        <Form className="form">
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="comment_id" label="消息id">
                <div className="right">{messageInfo.comment_id}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="nickname" label="用户名称">
                <div className="right">{messageInfo.nickname}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="channel" label="用户渠道">
                <div className="right">{messageInfo.channel}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="status" label="消息状态">
                <div className="right">
                  <span>{TABLE_AUDIT_STATUS[messageInfo.status]}</span>
                  <span className="operate-space">
                    {messageInfo.status === "pending" && (
                      <>
                        <Button
                          className="operate-space"
                          type="link"
                          onClick={() =>
                            auditMessage(
                              "approved",
                              messageInfo.comment_id,
                              messageInfo.channel
                            )
                          }
                        >
                          通过
                        </Button>
                        <Button
                          className="operate-space"
                          type="link"
                          onClick={() => {
                            setStatusModalVisible(true);
                            setCommentId(messageInfo.comment_id);
                            setChannel(messageInfo.channel);
                          }}
                        >
                          不通过
                        </Button>
                      </>
                    )}
                  </span>
                  {["approved"].includes(messageInfo.status) && (
                    <>
                      <Button
                        type="link"
                        className="operate-space"
                        onClick={() =>
                          auditMessage(
                            "pending",
                            messageInfo.comment_id,
                            messageInfo.channel
                          )
                        }
                      >
                        撤销
                      </Button>
                      <Button
                        type="link"
                        className="operate-space"
                        onClick={() =>
                          auditMessage(
                            "blocked",
                            messageInfo.comment_id,
                            messageInfo.channel
                          )
                        }
                      >
                        屏蔽
                      </Button>
                    </>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
          {messageInfo.status === "denied" && (
            <Row>
              <Col span={1} />
              <Col span={23}>
                <Form.Item name="created_at" label="不通过原因">
                  <div className="right">
                    {messageInfo.reason ? messageInfo.reason : "-"}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="created_at" label="留言生成时间">
                <div className="right">
                  {moment(messageInfo.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="content" label="留言标题">
                <div className="right">{messageInfo.title}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={22}>
              <Form.Item name="content" label="留言内容">
                <div
                  className="right msgInfo"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {messageInfo.content}
                </div>
              </Form.Item>
            </Col>
          </Row>
          {statusModalVisible && (
            <ModalStatus
              visible={statusModalVisible}
              auditMessageOk={auditMessageOk}
              commentId={commentId}
              channel={channel}
              cancel={() => setStatusModalVisible(false)}
            />
          )}
        </Form>
        {messageInfo.emojis && (
          <>
            <div className="topTitle">表情包详情</div>
            <div className="allProd">
              {newEmojis &&
                newEmojis.map((item, index) => {
                  return (
                    <div className="square" key={item.emoji_id}>
                      <div>
                        <Avatar src={EMOTION[item.emoji_id]} alt="表情包" />
                      </div>
                      <div>真实个数:{item.value.realCount}</div>
                      <div>伪造个数:{item.value.fakeCount}</div>
                      <Button
                        type="link"
                        disabled={messageInfo.status !== "approved"}
                        onClick={() => {
                          item.comment_id = messageInfo.comment_id;
                          item.channel = messageInfo.channel;
                          // eslint-disable-next-line no-sequences
                          return (
                            setEmojiInfo(item), setReactionModalVisival(true)
                          );
                        }}
                        className="detail"
                      >
                        点击修改表情数量
                      </Button>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {reactionModalVisival && (
          <ReactModal
            initInfoData={initInfoData}
            reactionModalVisival={reactionModalVisival}
            record={emojiInfo}
            cancel={() => setReactionModalVisival(false)}
          />
        )}
      </div>
    </div>
  );
}
