import React, { Component } from "react";
import { Modal } from "antd";
import Icon from "@ant-design/icons";
import "./Modal.scss";

class WrappedModal extends Component {
  render() {
    const { children, className, ...modalConfigs } = this.props;

    return (
      <Modal {...modalConfigs} wrapClassName="wrapped-content">
        {children}
      </Modal>
    );
  }
}

export default WrappedModal;
